import MixinList from '@/mixins/list'
import FormUser from '@/components/forms/form-user/form-user.vue'

export default {
  name: 'page-users',
  mixins: [
    MixinList,
  ],
  components: {
    FormUser,
  },
  data() {
    return {
      entity: {
        name: 'users',
        messages: {
          create: 'Пользователь создан',
          edit: 'Пользователь отредактирован',
          remove: 'Пользователь удален',
          restore: 'Пользователь восстановлен',
        },
      },
      params: {
        search: '',
        sort: '',
        direction: '',
        groupId: this.$route.params.id,
      },
      modals: {
        user: {
          show: false,
          data: null,
        },
      },
    }
  },
  methods: {
    convertUserData({ profile, ...data }) {
      return {
        ...data,
        ...profile,
      }
    },
  },
}
