import store from '@/store'
import consts from '@/common/consts'

const levels = consts.permissionLevels

export default {
  hasAccessToRoute(route) {
    const permissions = store.state.user.permissions
    const routePermissions = route.meta && route.meta.permissions
    if (routePermissions) {
      const entity = permissions.find(i => i.entityType === routePermissions.entity)
      const userLevel = levels[entity.permissionLevel]
      return levels[routePermissions.level] <= userLevel
    }
    return true
  },
  hasPermission(entity, level) {
    const permissions = store.state.user.permissions
    const userEntity = permissions.find(i => i.entityType === entity)
    const userLevel = levels[userEntity.permissionLevel]
    return levels[level] <= userLevel
  },
}
