import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('members/whitelist', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getBlackList({ rootState }, params) {
      return Http.get('members/blacklist', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    addToBlacklist({ rootState }, data) {
      return Http.post('members/blacklist', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    removeFromBlacklist(ctx, id) {
      return Http.delete(`members/blacklist/${id}`)
    },
  },
})
