import Http from '@/services/http'

export default ({
  namespaced: true,
  state: {
    permissionEntities: [],
    permissionLevels: [],
    locales: [],
    countries: [],
    dateTimeOffsetSources: [],
  },
  mutations: {
    setPermissionEntities(state, data) {
      state.permissionEntities = data
    },
    setPermissionLevels(state, data) {
      state.permissionLevels = data
    },
    setLocales(state, data) {
      state.locales = data
    },
    setCountries(state, data) {
      state.countries = data
    },
    setDateTimeOffsetSources(state, data) {
      state.dateTimeOffsetSources = data
    },
  },
  actions: {
    getPermissionEntities({ commit, state }) {
      if (!state.permissionEntities.length) {
        return Http.get('groups/permissions/entities').then(r => {
          commit('setPermissionEntities', r.list)
          return r.list
        })
      }
      return state.permissionEntities
    },
    getPermissionLevels({ commit, state }) {
      if (!state.permissionLevels.length) {
        return Http.get('groups/permissions/levels').then(r => {
          commit('setPermissionLevels', r.list)
          return r.list
        })
      }
      return state.permissionLevels
    },
    getLocales({ commit, state }) {
      if (!state.locales.length) {
        return Http.get('locales').then(r => {
          commit('setLocales', r.list)
          return r.list
        })
      }
      return state.locales
    },
    getCountries({ commit, state }) {
      if (!state.countries.length) {
        return Http.get('countries').then(r => {
          commit('setCountries', r.list)
          return r.list
        })
      }
      return state.countries
    },
    getDateTimeOffsetSources({ commit, state }) {
      if (!state.countries.length) {
        return Http.get('dateTimeOffsetSources').then(r => {
          commit('setDateTimeOffsetSources', r.list)
          return r.list
        })
      }
      return state.dateTimeOffsetSources
    },
  },
})
