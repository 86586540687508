import MixinForm from '@/mixins/form'
import consts from '@/common/consts'

export default {
  name: 'form-typical-action',
  mixins: [
    MixinForm,
  ],
  data() {
    return {
      form: {
        isDeleted: false,
        mnemocode: null,
        name: null,
        measurementUnit: null,
        foreignMnemocode: null,
      },
      measurementUnits: consts.measurementUnits,
    }
  },
}
