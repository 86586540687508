export default {
  inject: ['$validator'],
  props: {
    data: {
      default: () => {},
    },
    loading: {
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.data) {
      this.$utils.fillForm(this.data, this.form)
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.$emit('submit', {
            ...this.$utils.prepareFormData(this.form),
          })
        }
      })
    },
  },
}
