<template>
  <div class="quill-editor" :class="{ rounded: !showVars }">
    <div v-if="variables.length && showVars" class="variables">
      <tooltip>
        <strong>Список переменны</strong>х<br/><br/>
        Выберите переменную, в которую подставятся<br/>
        актуальные данные. Переменная появится<br/>
        в месте нахождения курсора
      </tooltip>

      <div
        v-for="v in variables"
        :key="v.variable"
        class="variable"
        @click="addVariable(v)"
      >
        <el-tag>
          {{ v.title }}
        </el-tag>
      </div>
    </div>

    <div ref="quill"></div>
  </div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

const Parchment = Quill.imports.parchment
const Delta = Quill.imports.delta

class Label extends Parchment.Embed {
  static create(v) {
    const node = super.create(v)
    node.innerText = v.title
    node.contentEditable = 'false'
    node.dataset.value = v.variable
    return node
  }

  static value(node) {
    return {
      title: node.textContent,
      variable: node.dataset.value,
    }
  }
}
Label.blotName = 'label'
Label.tagName = 'SPAN'
Label.className = 'ql-label'
Quill.register(Label)

export default {
  name: 'quill-textarea',
  data() {
    return {
      el: null,
      showVars: false,
    }
  },
  props: {
    options: {
      default: () => {},
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
    },
  },
  mounted() {
    this.el = new Quill(this.$refs.quill, {
      debug: 'error',
      theme: 'snow',
      placeholder: this.placeholder,
      modules: {
        toolbar: false,
      },
      ...this.options,
      readOnly: !!this.disabled,
    })

    this.el.enable(false)

    this.el.on('text-change', () => {
      this.$emit('input', this.getValue(this.$refs.quill.children[0].cloneNode(true)))
    })

    this.el.on('selection-change', range => {
      setTimeout(() => {
        this.showVars = !!range
      }, 10)
    })

    this.setValue()

    if (!this.disabled) {
      this.el.enable(true)
    }
  },
  watch: {
    variables(vars) {
      if (vars.length) {
        this.setValue()
      }
    },
  },
  methods: {
    setValue() {
      if (this.value) {
        this.el.pasteHTML(this.prepareValue(this.value))
      }
    },
    addVariable(v) {
      const index = this.el.getSelection(true).index
      const delta = new Delta()
        .retain(index)
        .insert({ label: v })
      this.el.updateContents(delta)
      this.el.setSelection(index + 1, Quill.sources.SILENT)
    },
    prepareValue(value) {
      this.variables.forEach(v => {
        value = value.replaceAll(`{{${v.variable}}}`, Label.create(v).outerHTML)
      })
      return value
    },
    getValue(html) {
      if (html.innerHTML === '<p><br></p>') {
        return ''
      }
      Array.from(html.getElementsByTagName('p')).forEach(p => {
        const labels = p.getElementsByClassName('ql-label')
        Array.from(labels).forEach(l => {
          p.replaceChild(document.createTextNode(`{{${l.dataset.value}}}`), l)
        })
      })
      return html.innerText
    },
  },
}
</script>

<style lang="scss">
.quill-editor {
  &.has-error {
    .ql-container, .variables {
      border-color: #f56c6c !important;
    }
  }

  &.rounded {
    .ql-container {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .ql-container {
    font-family: inherit;

    .ql-editor {
      padding: 8px 15px;
      line-height: 22px;
    }
  }
  .ql-label {
    background-color: #eaeaeb;
    border-color: #d4d5d6;
    color: #282b34;
    display: inline-block;
    padding: 1px 8px;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    white-space: nowrap;
    font-weight: 600;
    line-height: normal;
  }

  .ql-label button {
    margin-left: 0.3em;
  }

  .variables {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $form-border;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 15px 6px 15px;
    border-bottom: none;
    background: #f9f9f9;

    .el-tooltip {
      height: 22px;
      margin-right: 4px;
    }

    .variable {
      margin: 0 4px 4px 0;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      .el-tag {
        display: block;
        margin: 0;
        line-height: 20px;
        height: 22px;
      }
    }
  }
}
</style>
