import { mapState, mapGetters } from 'vuex'

export default {
  name: 'access-object-picker',
  data() {
    return {
      show: false,
      value: null,
    }
  },
  computed: {
    ...mapState({
      ao: state => state.accessObjects,
    }),
    ...mapGetters({
      current: 'accessObjects/current',
    }),
    tree() {
      return this.ao.tree
        .filter(b => !b.isDeleted)
        .map(b => ({
          ...b,
          children: b.children.filter(f => !f.isDeleted).map(e => ({
            ...e,
            children: e.children.filter(e => !e.isDeleted),
          })),
        }))
    },
    currentKey() {
      const c = this.value || this.current
      return c ? `${c.id}-${c.type}` : null
    },
    selected() {
      return this.current ? this.current.title : 'Выберите объект'
    },
    expanded() {
      if (this.current) {
        return this.getExpanded(this.current.id, this.current.type, this.ao.tree)
      }
      return []
    },
  },
  methods: {
    change({ id, type, isAvailable }) {
      this.value = { id, type, isAvailable }
    },
    submit() {
      this.$store.dispatch('accessObjects/set', this.value).then(() => {
        this.$router.push({ name: 'access-objects' }).catch(() => {})
      })
      this.show = false
    },
    getExpanded(id, type, tree) {
      let result = null
      let path = []
      const find = group => {
        group.forEach(item => {
          if (item.id === id && item.type === type) {
            result = item
          } else if (item.children) {
            if (!result) {
              path.push(item.key)
            }
            find(item.children)
          }
          if (item.type === 'BRAND' && !result) {
            path = []
          }
        })
      }
      find(tree)
      return path
    },
    reset() {
      this.value = null
      this.$refs.tree.setCurrentKey(null)
      this.$store.dispatch('accessObjects/reset').then(() => {
        this.$router.push({ name: 'access-objects' }).catch(() => {})
      })
    },
  },
}
