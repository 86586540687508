import cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import MixinSearching from '@/mixins/searching'

export default {
  name: 'chain-targets',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    targetId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      statuses: [],
    }
  },
  async created() {
    this.statuses = await this.$store.dispatch('targets/getNotificationTriggers')
    this.search.list = this.value.map(t => ({ id: t.id, name: t.name }))
  },
  methods: {
    add() {
      this.$emit('input', [
        ...this.value,
        {
          name: null,
          status: null,
        },
      ])
    },
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
    changeOrder(e) {
      if (e.moved) {
        const value = cloneDeep(this.value)
        const tmp = value[e.moved.oldIndex]
        value[e.moved.oldIndex] = value[e.moved.newIndex]
        value[e.moved.newIndex] = tmp
        this.$emit('input', value)
      }
    },
  },
}
