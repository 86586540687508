import { EventBus } from '@/services/event-bus'

export default {
  name: 'app',
  created() {
    EventBus.$on('http-error', e => {
      e = e.response
      if (e.data && e.data.error && this.$utils.isAuthError(e)) {
        this.$store.dispatch('user/logout').then(() => {
          this.$router.push({ name: 'login' })
        })
      } else {
        let message
        const code = e?.status
        switch (code) {
          case 400:
            if (e.data.error.code === 'VALIDATION_FIELDS_ERROR') {
              message = []
              Object.keys(e.data.error.fields).forEach(field => {
                message.push(e.data.error.fields[field])
              })
              message = message.join(', ')
            }
            break
          default:
            message = e?.data?.error?.message || e.statusText
        }

        if (message) {
          this.$message({
            showClose: true,
            message,
            type: 'error',
            duration: 5000,
          })
        }
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('http-error')
  },
}
