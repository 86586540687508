export default {
  name: 'upload-gallery',
  data() {
    return {
      loading: false,
      params: {
        limit: 24,
        offset: 0,
      },
      total: null,
      list: [],
    }
  },
  props: [
    'value',
  ],
  created() {
    this.getList()
  },
  computed: {
    page() {
      return Math.floor(this.params.offset / this.params.limit) + 1
    },
  },
  methods: {
    getList() {
      this.loading = true
      this.$store.dispatch('files/getList', this.params).then(data => {
        this.total = data.total
        this.list = data.list
      }).finally(() => {
        this.loading = false
      })
    },
    remove(id) {
      console.log(id)
      this.$store.dispatch('files/delete', id).then(() => {
        this.getList()
      }).catch(e => {
        console.log(e)
      })
    },
    changePagination(page) {
      this.params.offset = this.params.limit * (page - 1)
      this.getList()
    },
    select(item) {
      this.$emit('input', item)
    },
  },
}
