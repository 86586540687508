import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  state: {
    types: [],
    notificationTriggers: [
      { title: 'Момент выдачи награды', value: 'ISSUED' },
      { title: 'Наступление даты начала доступности награды', value: 'ACTIVITY' },
      { title: 'Наступление даты завершения доступности (сгорания) награды', value: 'VALIDITY' },
    ],
  },
  mutations: {
    setTypes(state, data) {
      state.types = data
    },
  },
  actions: {
    getTypes({ commit, state }) {
      if (!state.types.length) {
        return Http.get('rewards/types').then(r => {
          commit('setTypes', r.list)
          return r.list
        })
      }
      return state.types
    },
    getList({ rootState }, params) {
      return Http.get('rewards', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`rewards/${id}`).then(r => ({
        ...r,
        attachments: utils.getAttachmentsWithCdnImages(r.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('rewards', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`rewards/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`rewards/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`rewards/restore/${id}`)
    },
  },
})
