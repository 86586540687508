import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  state: {
    tree: [],
    id: null,
    type: null,
  },
  getters: {
    current(state) {
      return utils.findAccessObject(state.id, state.type, state.tree)
    },
  },
  mutations: {
    setTree(state, tree) {
      state.tree = tree.map(b => ({
        ...b,
        key: `${b.id}-${b.type}`,
        children: b.frontoffices.map(f => ({
          ...f,
          key: `${f.id}-${f.type}`,
          children: f.entrypoints.map(e => ({
            ...e,
            key: `${e.id}-${e.type}`,
          })),
        })),
      }))
    },
    set(state, { id, type }) {
      state.id = id
      state.type = type
    },
    reset(state) {
      state.id = null
      state.type = null
    },
  },
  actions: {
    getTree({ state, dispatch, commit }) {
      return Http.get('accessObjects').then(r => {
        commit('setTree', r.list)
        if (!state.id && !state.type) {
          const id = Number(localStorage.getItem('aoId'))
          const type = localStorage.getItem('aoType')
          const object = utils.findAccessObject(id, type, state.tree)
          if (object) {
            return dispatch('set', { id, type }).then(() => {
              return r.list
            })
          }
          return r.list
        }
        return r.list
      })
    },
    create(ctx, { data, type }) {
      return Http.post(`accessObjects/${type}`, data)
    },
    edit(ctx, { data, type, id }) {
      return Http.put(`accessObjects/${type}/${id}`, data)
    },
    delete(ctx, { type, id }) {
      return Http.delete(`accessObjects/${type}/delete/${id}`)
    },
    restore(ctx, { type, id }) {
      return Http.post(`accessObjects/${type}/restore/${id}`)
    },
    set({ commit, dispatch }, { id, type }) {
      localStorage.setItem('aoId', id)
      localStorage.setItem('aoType', type)
      commit('set', { id, type })
      return dispatch('user/getPermissions', true, { root: true })
    },
    reset({ dispatch, commit }) {
      localStorage.removeItem('aoId')
      localStorage.removeItem('aoType')
      commit('set', { id: null, type: null })
      return dispatch('user/getPermissions', false, { root: true })
    },
  },
})
