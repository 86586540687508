var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-questions"},[_c('page-header',{attrs:{"title":"Вопросы","add-title":"Добавить вопрос","show-add":_vm.$guard.hasPermission('QUESTION', 'WRITER')},on:{"add":function($event){return _vm.$router.push({
      name: 'question',
      params: { id: 'create' },
      query: {
        from: _vm.$route.query,
      }
    })}}},[_c('template',{slot:"actions"},[_c('el-switch',{attrs:{"inactive-text":"Показывать удаленные"},on:{"input":_vm.changeIsExtended},model:{value:(_vm.params.isExtended),callback:function ($$v) {_vm.$set(_vm.params, "isExtended", $$v)},expression:"params.isExtended"}})],1)],2),_c('div',{staticClass:"filters"},[_c('input-search',{on:{"search":_vm.changeFilters},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.table),expression:"loading.table"}],staticClass:"table-wrapper"},[(!_vm.loading.table)?_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"default-sort":{
        prop: _vm.params.sort,
        order: _vm.getOrder(),
      },"row-key":"id"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Название","sortable":"custom","prop":"name"}}),_c('el-table-column',{attrs:{"align":"right","width":"250","class-name":"actions"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"el-icon-edit","circle":"","title":"Редактировать вопрос"},on:{"click":function($event){return _vm.$router.push({
              name: 'question',
              params: { id: scope.row.id },
              query: {
                from: _vm.$route.query,
              }
            })}}}),_c('btn-delete',{attrs:{"is-deleted":scope.row.isDeleted,"loading":_vm.loading.remove === scope.row.id,"title-delete":"Удалить вопрос","confirm-text":"Вы действительно хотите удалить вопрос?"},on:{"submit":function($event){scope.row.isDeleted ? _vm.restore(scope.row) : _vm.remove(scope.row)}}})]}}],null,false,495186007)})],1):_vm._e()],1),_c('el-row',{staticStyle:{"padding":"16px 0"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.page,"page-size":_vm.params.limit,"hide-on-single-page":""},on:{"current-change":_vm.changePagination}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }