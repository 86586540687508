import Http from '@/services/http'

export default ({
  namespaced: true,
  actions: {
    getList(ctx, { params, rewardId }) {
      return Http.get(`rewards/${rewardId}/instances`, { params })
    },
    create(ctx, { data, rewardId }) {
      return Http.post(`rewards/${rewardId}/instances`, data)
    },
    delete(ctx, id) {
      return Http.delete(`rewards/instances/${id}`)
    },
    import(ctx, { rewardId, file }) {
      const fd = new FormData()
      fd.append('file', file)
      return Http.post(`rewards/${rewardId}/instances/import`, fd, {
        'Content-Type': 'multipart/form-data',
      })
    },
  },
})
