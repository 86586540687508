import { mapGetters, mapState } from 'vuex'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import AccessObjectPicker from '@/components/access-object-picker/access-object-picker.vue'

export default {
  name: 'dashboard',
  components: {
    AccessObjectPicker,
    PerfectScrollbar,
  },
  data() {
    return {
      menu: [
        {
          component: 'el-submenu',
          index: 'access-objects',
          title: 'Объекты доступа',
          icon: 'settings',
        },
        {
          alias: 'targets',
          title: 'Цели',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      accessObject: 'accessObjects/current',
    }),
    ...mapState({
      user: state => state.user.data,
      permissions: state => state.user.permissions,
    }),
    avatarBg() {
      if (this.user.profile.avatar.imageUri) {
        return { backgroundImage: `url(${this.user.profile.avatar.imageUri.imageUri})` }
      } else if (this.user.profile.avatar.gradient) {
        return { background: `linear-gradient(180deg, ${this.user.profile.avatar.gradient.colorStart},
        ${this.user.profile.avatar.gradient.colorStop})` }
      }
      return null
    },
    username() {
      return `${this.user.profile.surname} ${this.user.profile.givenName.charAt(0)}.`
    },
    activeRoute() {
      const mapping = {
        target: 'targets',
        group: 'groups',
        'group-users': 'groups',
        'custom-currency': 'custom-currencies',
        reward: 'rewards',
        lootbox: 'lootboxes',
        quiz: 'quizes',
        question: 'questions',
        'quiz-questions': 'questions',
        lottery: 'lotteries',
        'notification-template': 'notification-templates',
      }
      return mapping[this.$route.name] || this.$route.name
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
      this.$router.push({ name: 'login' })
    },
    select(name) {
      this.$router.push({ name }).catch(() => {})
    },
    hasAccessToRoute(name) {
      return this.$guard.hasAccessToRoute(this.$router.resolve({ name }).route)
    },
    updateScroll() {
      setTimeout(() => {
        this.$refs.scrollbar.update()
      }, 500)
    },
  },
}
