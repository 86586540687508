import { mapState } from 'vuex'
import FormBrand from '@/components/forms/form-brand/form-brand.vue'
import FormFrontoffice from '@/components/forms/form-frontoffice/form-frontoffice.vue'
import FormEntrypoint from '@/components/forms/form-entrypoint/form-entrypoint.vue'

export default {
  name: 'page-access-objects',
  inject: ['$validator'],
  components: {
    FormBrand,
    FormFrontoffice,
    FormEntrypoint,
  },
  data() {
    return {
      loading: {
        table: false,
        remove: false,
        modal: false,
      },
      modals: {
        BRAND: {
          show: false,
          data: null,
        },
        FRONTOFFICE: {
          show: false,
          data: null,
        },
        ENTRYPOINT: {
          show: false,
          data: null,
        },
      },
      isExtended: false,
    }
  },
  computed: {
    ...mapState({
      ao: state => state.accessObjects,
      user: state => state.user.data,
    }),
    tree() {
      return this.ao.tree
        .filter(b => b.isAvailable && (!this.isExtended ? !b.isDeleted : true))
        .map(b => ({
          ...b,
          children: b.children.filter(f => f.isAvailable && (!this.isExtended ? !f.isDeleted : true)).map(e => ({
            ...e,
            children: e.children.filter(e => e.isAvailable && (!this.isExtended ? !e.isDeleted : true)),
          })),
        }))
    },
  },
  methods: {
    save(type, data) {
      this.loading.modal = true
      const id = this.modals[type].data.id
      this.$store.dispatch(`accessObjects/${id ? 'edit' : 'create'}`, {
        data: this.$utils.prepareFormData(data),
        id,
        type: this.$utils.getAccessObjectType(type)?.many,
      }).then(() => {
        this.$message({
          message: `Объект доступа ${id ? 'отредактирован' : 'создан'}`,
          type: 'success',
        })
        this.modals[type].show = false
        this.loading.table = true
        this.$store.dispatch('accessObjects/getTree').then(() => {
          this.loading.table = false
        })
      }).catch(e => {
        this.$utils.addServerErrors(e, this.errors)
      }).finally(() => {
        this.loading.modal = false
      })
    },
    remove(type, data) {
      this.loading.remove = data.key
      this.$store.dispatch('accessObjects/delete', {
        type: this.$utils.getAccessObjectType(type)?.many,
        id: data.id,
      }).then(() => {
        this.$message({
          message: 'Объект доступа удален',
          type: 'success',
        })
        this.$store.dispatch('accessObjects/getTree').then(() => {
          this.loading.remove = false
        })
      }).catch(e => {
        this.loading.remove = false
        if (e.response.status === 400) {
          this.$message({
            message: e.response.data.error.message,
            type: 'warning',
            duration: 5000,
          })
        }
      })
    },
    restore(type, data) {
      this.loading.remove = data.key
      this.$store.dispatch('accessObjects/restore', {
        type: this.$utils.getAccessObjectType(type)?.many,
        id: data.id,
      }).then(() => {
        this.$message({
          message: 'Объект доступа восстановлен',
          type: 'success',
        })
        this.$store.dispatch('accessObjects/getTree').then(() => {
          this.loading.remove = false
        })
      }).catch(e => {
        this.loading.remove = false
        if (e.response.status === 400) {
          this.$message({
            message: e.response.data.error.message,
            type: 'warning',
            duration: 5000,
          })
        }
      })
    },
    showItemForm(type, data = {}) {
      this.modals[type].data = data
      this.modals[type].show = true
    },
    addAccessObjectChild(data) {
      this.modals[data.type].show = false
      switch (data.type) {
        case 'BRAND':
          this.showItemForm('FRONTOFFICE', { brandId: data.id })
          break
        default:
          this.showItemForm('ENTRYPOINT', { frontofficeId: data.id })
      }
    },
  },
}
