import MixinList from '@/mixins/list'

export default {
  name: 'page-rewards',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'rewards',
        messages: {
          save: 'Награда сохранена',
          remove: 'Награда удалена',
          restore: 'Награда восстановлена',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
        isActive: null,
        type: null,
      },
      types: [],
      activities: [
        { title: 'Активна', value: true },
        { title: 'Не активна', value: false },
      ],
    }
  },
  created() {
    this.$store.dispatch('rewards/getTypes').then(types => {
      this.types = types
    })
  },
}
