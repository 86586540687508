<template>
  <el-tooltip placement="top" effect="light" popper-class="field-tooltip">
    <div slot="content">
      <slot />
    </div>

    <i class="tooltip-icon el-icon-question" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'tooltip',
}
</script>

<style lang="scss" scoped>
.tooltip-icon {
  font-size: 18px;
  color: $dark;
  position: relative;
  bottom: -2px;

  &.inline {
    bottom: -3px;
    margin-left: 4px;
  }
}
</style>

<style lang="scss">
.field-tooltip {
  border-color: $form-border !important;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  max-width: 500px;

  .popper__arrow {
    border-top-color: $form-border!important;
    border-bottom-color: $form-border!important;
  }

  ul {
    padding-left: 14px;
    margin: 10px 0;
    list-style-type: disc;

    li {
      margin: 4px;
    }
  }

  p {
    margin: 10px 0;
  }
}
</style>
