import cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import MixinSearching from '@/mixins/searching'
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'notifications',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    entity: {
      type: String,
      default: 'TARGET',
    },
  },
  created() {
    this.search.list = uniqBy(
      this.value.map(n => ({ id: n.notificationTemplateId, name: n.notificationTemplateName })),
      'id',
    )
  },
  computed: {
    triggers() {
      const mapping = { TARGET: 'targets', REWARD: 'rewards' }
      return this.$store.state[mapping[this.entity]].notificationTriggers
    },
    triggerFieldName() {
      const mapping = { TARGET: 'status', REWARD: 'trigger' }
      return mapping[this.entity]
    },
  },
  methods: {
    add() {
      this.$emit('input', [
        ...this.value,
        {
          notificationTemplateId: null,
          [this.triggerFieldName]: null,
          delay: null,
        },
      ])
    },
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
    changeOrder(e) {
      if (e.moved) {
        const value = cloneDeep(this.value)
        const tmp = value[e.moved.oldIndex]
        value[e.moved.oldIndex] = value[e.moved.newIndex]
        value[e.moved.newIndex] = tmp
        this.$emit('input', value)
      }
    },
  },
}
