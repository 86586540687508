import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('customCurrencies', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`customCurrencies/${id}`).then(c => ({
        ...c,
        image: utils.getCdnImage(c.image, 400, 400),
        attachments: utils.getAttachmentsWithCdnImages(c.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('customCurrencies', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`customCurrencies/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`customCurrencies/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`customCurrencies/restore/${id}`)
    },
  },
})
