import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '@/common/utils'
import guard from '@/common/guard'
import Login from '../views/login/login.vue'
import Dashboard from '../views/dashboard/dashboard.vue'
import AccessObjects from '../views/access-objects/access-objects.vue'
import Groups from '../views/groups/groups.vue'
import Group from '../views/groups/group/group.vue'
import GroupUsers from '../views/groups/users/users.vue'
import Users from '../views/users/users.vue'
import Targets from '../views/targets/targets.vue'
import Target from '../views/targets/target/target.vue'
import TypicalActions from '../views/targets/typical-actions/typical-actions.vue'
import CustomCurrencies from '../views/custom-currencies/custom-currencies.vue'
import CustomCurrency from '../views/custom-currencies/custom-currency/custom-currency.vue'
import Rewards from '../views/rewards/rewards.vue'
import Reward from '../views/rewards/reward/reward.vue'
import RewardInstances from '../views/rewards/reward-instances/reward-instances.vue'
import Lootboxes from '../views/lootboxes/lootboxes.vue'
import Lootbox from '../views/lootboxes/lootbox/lootbox.vue'
import Quizes from '../views/quizes/quizes.vue'
import Quiz from '../views/quizes/quiz/quiz.vue'
import Questions from '../views/quizes/questions/questions.vue'
import Question from '../views/quizes/question/question.vue'
import QuizQuestions from '../views/quizes/quiz-questions/quiz-questions.vue'
import Lotteries from '../views/lotteries/lotteries.vue'
import Lottery from '../views/lotteries/lottery/lottery.vue'
import Me from '../views/me/me.vue'
import Members from '../views/members/members.vue'
import MembersBlacklist from '../views/members/blacklist/blacklist.vue'
import NotificationTemplates from '../views/notification-templates/notification-templates.vue'
import NotificationTemplate from '../views/notification-templates/notification-template/notification-template.vue'
import store from '../store/index'

Vue.use(VueRouter)

const getRoutePermissionData = (entity, level) => ({
  permissions: { level, entity },
})
const defaultRouteName = 'access-objects'
const routes = [
  {
    path: '/',
    redirect: `/${defaultRouteName}`,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
      title: 'Авторизация',
    },
  },
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: 'access-objects',
        component: AccessObjects,
        name: 'access-objects',
      },
      {
        path: 'groups/:id/users',
        component: GroupUsers,
        name: 'group-users',
        meta: {
          ...getRoutePermissionData('GROUP', 'READER'),
        },
      },
      {
        path: 'groups/:id',
        component: Group,
        name: 'group',
        meta: {
          ...getRoutePermissionData('GROUP', 'READER'),
        },
      },
      {
        path: 'groups',
        component: Groups,
        name: 'groups',
        meta: {
          ...getRoutePermissionData('GROUP', 'READER'),
        },
      },
      {
        path: 'users',
        component: Users,
        name: 'users',
        meta: {
          ...getRoutePermissionData('USER', 'READER'),
        },
      },
      {
        path: 'targets/typical-actions',
        component: TypicalActions,
        name: 'target-typical-actions',
        meta: {
          ...getRoutePermissionData('TARGET', 'READER'),
        },
      },
      {
        path: 'targets/:id',
        component: Target,
        name: 'target',
        meta: {
          ...getRoutePermissionData('TARGET', 'READER'),
        },
      },
      {
        path: 'targets',
        component: Targets,
        name: 'targets',
        meta: {
          ...getRoutePermissionData('TARGET', 'READER'),
        },
      },
      {
        path: 'custom-currencies/:id',
        component: CustomCurrency,
        name: 'custom-currency',
        meta: {
          ...getRoutePermissionData('CUSTOM_CURRENCY', 'READER'),
        },
      },
      {
        path: 'custom-currencies',
        component: CustomCurrencies,
        name: 'custom-currencies',
        meta: {
          ...getRoutePermissionData('CUSTOM_CURRENCY', 'READER'),
        },
      },
      {
        path: 'rewards/:id',
        component: Reward,
        name: 'reward',
        meta: {
          ...getRoutePermissionData('REWARD', 'READER'),
        },
      },
      {
        path: 'rewards',
        component: Rewards,
        name: 'rewards',
        meta: {
          ...getRoutePermissionData('REWARD', 'READER'),
        },
      },
      {
        path: 'rewards/:id/instances',
        component: RewardInstances,
        name: 'reward-instances',
        meta: {
          ...getRoutePermissionData('REWARD_INSTANCE', 'READER'),
        },
      },
      {
        path: 'lootboxes/:id',
        component: Lootbox,
        name: 'lootbox',
        meta: {
          ...getRoutePermissionData('LOOT_BOX', 'READER'),
        },
      },
      {
        path: 'lootboxes',
        component: Lootboxes,
        name: 'lootboxes',
        meta: {
          ...getRoutePermissionData('LOOT_BOX', 'READER'),
        },
      },
      {
        path: 'quizes/:id/questions',
        component: QuizQuestions,
        name: 'quiz-questions',
        meta: {
          ...getRoutePermissionData('QUESTION', 'READER'),
        },
      },
      {
        path: 'quizes/questions/:id',
        component: Question,
        name: 'question',
        meta: {
          ...getRoutePermissionData('QUESTION', 'READER'),
        },
      },
      {
        path: 'quizes/questions',
        component: Questions,
        name: 'questions',
        meta: {
          ...getRoutePermissionData('QUESTION', 'READER'),
        },
      },
      {
        path: 'quizes/:id',
        component: Quiz,
        name: 'quiz',
        meta: {
          ...getRoutePermissionData('QUIZ', 'READER'),
        },
      },
      {
        path: 'quizes',
        component: Quizes,
        name: 'quizes',
        meta: {
          ...getRoutePermissionData('QUIZ', 'READER'),
        },
      },
      {
        path: 'lotteries/:id',
        component: Lottery,
        name: 'lottery',
        meta: {
          ...getRoutePermissionData('LOTTERY', 'READER'),
        },
      },
      {
        path: 'lotteries',
        component: Lotteries,
        name: 'lotteries',
        meta: {
          ...getRoutePermissionData('LOTTERY', 'READER'),
        },
      },
      {
        path: 'me',
        component: Me,
        name: 'me',
      },
      {
        path: 'members',
        component: Members,
        name: 'members',
        meta: {
          ...getRoutePermissionData('PROFILE_WHITE_LIST', 'READER'),
        },
      },
      {
        path: 'members/blacklist',
        component: MembersBlacklist,
        name: 'members-blacklist',
        meta: {
          ...getRoutePermissionData('PROFILE_BLACK_LIST', 'READER'),
        },
      },
      {
        path: 'notification-templates',
        component: NotificationTemplates,
        name: 'notification-templates',
        meta: {
          ...getRoutePermissionData('NOTIFICATION_TEMPLATE', 'READER'),
        },
      },
      {
        path: 'notification-templates/:id',
        component: NotificationTemplate,
        name: 'notification-template',
        meta: {
          ...getRoutePermissionData('NOTIFICATION_TEMPLATE', 'READER'),
        },
      },
    ],
  },
  { path: '*', redirect: defaultRouteName },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  parseQuery: (...args) => require('qs').parse(...args, {
    decoder(str, decoder, charset) {
      const strWithoutPlus = str.replace(/\+/g, ' ')

      if (charset === 'iso-8859-1') {
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str)
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      }

      if (str in keywords) {
        return keywords[str]
      }

      try {
        return decodeURIComponent(strWithoutPlus)
      } catch (e) {
        return strWithoutPlus
      }
    },
  }),
  stringifyQuery: (...args) => require('qs').stringify(...args, {
    addQueryPrefix: true,
  }),
})

router.beforeEach((to, from, next) => {
  const hasAuth = to.meta.auth || to.meta.auth === undefined
  const user = store.state.user
  if (!hasAuth || (!!user.data && guard.hasAccessToRoute(to))) {
    next()
  } else if (!user.data) {
    store.dispatch('user/init').then(() => {
      if (guard.hasAccessToRoute(to)) {
        next()
      } else {
        next({ name: defaultRouteName })
      }
    }).catch(e => {
      if (hasAuth && utils.isAuthError(e.response)) {
        store.dispatch('user/logout').then(() => {
          next({ name: 'login' })
        })
      } else {
        next()
      }
    })
  } else {
    next({ name: 'login' })
  }
})

export default router
