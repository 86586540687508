import MixinList from '@/mixins/list'
import FormTypicalAction from '@/components/forms/form-typical-action/form-typical-action.vue'

export default {
  name: 'page-typical-actions',
  mixins: [
    MixinList,
  ],
  components: {
    FormTypicalAction,
  },
  data() {
    return {
      entity: {
        name: 'typicalActions',
        messages: {
          create: 'Типовое действие создано',
          edit: 'Типовое действие отредактировано',
          remove: 'Типовое действие удалено',
          restore: 'Типовое действие восстановлено',
        },
      },
    }
  },
}
