import MixinForm from '@/mixins/form'

export default {
  name: 'form-reward-instance',
  mixins: [
    MixinForm,
  ],
  data() {
    return {
      form: {
        value: null,
      },
    }
  },
}
