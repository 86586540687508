import { render, staticRenderFns } from "./form-import-reward-instances.vue?vue&type=template&id=c8a77150&scoped=true&"
import script from "./form-import-reward-instances.js?vue&type=script&lang=js&"
export * from "./form-import-reward-instances.js?vue&type=script&lang=js&"
import style0 from "./form-import-reward-instances.scss?vue&type=style&index=0&id=c8a77150&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8a77150",
  null
  
)

export default component.exports