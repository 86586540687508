export default {
  name: 'page-quiz',
  inject: ['$validator'],
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        name: null,
        mnemocode: null,
        image: null,
        isActive: false,
        title: [],
        description: [],
        attachments: [],
      },
      activeTab: 'common',
    }
  },
  async created() {
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('quizes/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      this.loading.form = false
    }
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`quizes/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData(this.form),
          }).then(() => {
            this.$message({
              message: `Тест ${this.id ? 'отредактирован' : 'создан'}`,
              type: 'success',
            })
            this.$router.push({ name: 'quizes' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('quizes/delete', this.data.id).then(() => {
        this.$router.push({ name: 'quizes' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('quizes/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'quizes', query: this.$route.query.from })
    },
  },
}
