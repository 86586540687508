import consts from '@/common/consts'
import LocalizedStringSet from '@/components/ui/localized-string-set/localized-string-set.vue'

const mapValueFields = type => {
  const fields = {
    GRADIENT: {
      colorStart: null,
      colorStop: null,
    },
    LOCALIZED_STRING_SET: [],
  }
  return fields[type] || null
}

export default {
  name: 'attachments',
  components: {
    LocalizedStringSet,
  },
  inject: ['$validator'],
  props: {
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    imageModelFormat: {
      type: String,
      default: 'string',
    },
  },
  data() {
    return {
      types: consts.attachmentTypes,
    }
  },
  methods: {
    add() {
      this.$emit('input', [
        ...this.value,
        {
          name: null,
          mnemocode: null,
          type: null,
          value: null,
        },
      ])
    },
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
    selectType(attachment, type) {
      this.$set(attachment, 'value', mapValueFields(type))
    },
    validateField(name) {
      this.$nextTick(() => {
        this.$validator.validate(name)
      })
    },
    isURIField(type) {
      return ['VIDEO', 'STATIC_PAGE', 'BANNER', 'DEEPLINK', 'LOTTIE'].includes(type)
    },
  },
}
