import draggable from 'vuedraggable'
import MixinSearching from '@/mixins/searching'
import consts from '@/common/consts'

const reward = {
  rewardId: null,
  count: 1,
  issuedCount: 0,
}

export default {
  name: 'page-lottery',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    draggable,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        name: null,
        mnemocode: null,
        isActive: false,
        date: null,
        status: 'AWAITING_LAUNCH',
        streamUrl: null,
        title: [],
        description: [],
        attachments: [],
        rewards: [
          { ...reward },
        ],
        targetId: null,
      },
      activeTab: 'common',
      statuses: consts.lotteryStatuses,
    }
  },
  async created() {
    this.search.list = {
      rewards: [],
      targets: [],
    }
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('lotteries/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      this.search.list.rewards = this.data.rewards.map(r => ({ id: r.rewardId, name: r.rewardName }))
      if (this.data.targetId) {
        this.search.list.targets = [
          { id: this.data.targetId, name: this.data.targetName },
        ]
      }
      this.loading.form = false
    }
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`lotteries/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData({
              ...this.form,
              rewards: this.form.rewards.map((i, index) => ({
                ...i,
                order: index + 1,
              })),
            }),
          }).then(lottery => {
            if (this.data && this.data.status !== this.form.status) {
              return this.$store.dispatch('lotteries/changeStatus', {
                id: lottery.id,
                status: this.form.status,
              })
            }
            return true
          }).then(() => {
            this.$message({
              message: `Розыгрыш ${this.id ? 'отредактирован' : 'создан'}`,
              type: 'success',
            })
            this.$router.push({ name: 'lotteries' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('lotteries/delete', this.data.id).then(() => {
        this.$router.push({ name: 'lotteries' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('lotteries/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'lotteries', query: this.$route.query.from })
    },
    addReward() {
      this.form.rewards.push({ ...reward })
    },
    removeReward(index) {
      this.form.rewards.splice(index, 1)
    },
  },
}
