import { mapGetters } from 'vuex'

export default {
  inject: ['$validator'],
  data() {
    return {
      entity: {
        getList: 'getList',
      },
      list: [],
      loading: {
        table: false,
        remove: false,
        modal: false,
      },
      params: {
        limit: 10,
        offset: 0,
        isExtended: false,
      },
      total: null,
      item: {
        data: {},
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      accessObject: 'accessObjects/current',
    }),
    page() {
      return Math.floor(this.params.offset / this.params.limit) + 1
    },
  },
  created() {
    this.params = { ...this.params, ...this.$utils.prepareQuery(this.$route.query) }
    this.getList()
  },
  methods: {
    getList() {
      this.loading.table = true
      const action = `${this.entity.name}/${this.entity.getList}`
      this.$store.dispatch(action, this.getQueryParams()).then(data => {
        this.total = data.total
        this.list = data.list
      }).finally(() => {
        this.loading.table = false
      })
    },
    changePagination(page) {
      this.params.offset = this.params.limit * (page - 1)
      this.$router.replace({ query: this.getQueryParams() })
      this.getList()
    },
    changeFilters() {
      this.params.offset = 0
      this.$router.replace({ query: this.getQueryParams() })
      this.getList()
    },
    sortChange({ prop, order }) {
      const mapNames = {
        ascending: 'ASC',
        descending: 'DESC',
      }

      this.params.offset = 0
      this.params.sort = order ? prop : ''
      this.params.direction = mapNames[order] || null
      this.$router.push({ query: this.getQueryParams() })
      this.getList()
    },
    showItemForm(data = {}) {
      this.item.errors = {}
      this.item.data = data
      this.item.show = true
    },
    save(data) {
      this.loading.modal = true
      const id = this.item.data.id
      this.$store.dispatch(`${this.entity.name}/${id ? 'edit' : 'create'}`, {
        data,
        id,
      }).then(() => {
        this.$message({
          message: this.entity.messages[id ? 'edit' : 'create'],
          type: 'success',
        })
        this.item.show = false
        this.getList()
      }).catch(e => {
        this.$utils.addServerErrors(e, this.errors)
      }).finally(() => {
        this.loading.modal = false
      })
    },
    remove(data) {
      this.loading.remove = data.id
      this.$store.dispatch(`${this.entity.name}/delete`, data.id).then(() => {
        this.$message({
          message: this.entity.messages.remove,
          type: 'success',
        })
        this.list.find(i => i.id === data.id).isDeleted = true
      }).catch(e => {
        e = e.response
        if (e.data && e.data.error && e.data.error.message) {
          this.$message({
            message: e.data.error.message,
            type: 'warning',
          })
        }
      }).finally(() => {
        this.loading.remove = false
      })
    },
    restore(data) {
      this.loading.remove = data.id
      this.$store.dispatch(`${this.entity.name}/restore`, data.id).then(() => {
        this.$message({
          message: this.entity.messages.restore,
          type: 'success',
        })
        this.list.find(i => i.id === data.id).isDeleted = false
      }).catch(e => {
        if (e.status === 400) {
          this.$message({
            message: e.response.data.error.message,
            type: 'warning',
            duration: 5000,
          })
        }
      }).finally(() => {
        this.loading.remove = false
      })
    },
    getQueryParams() {
      return Object.keys(this.params).reduce((acc, field) => {
        if ((this.params[field] || this.params[field] === false) || this.params[field] === 0) {
          if (Array.isArray(this.params[field])) {
            acc[field] = this.params[field].join(',')
          } else {
            acc[field] = this.params[field]
          }
        }
        return acc
      }, {})
    },
    getOrder() {
      const order = this.$utils.upperToCamel(this.params.direction)
      const orders = {
        asc: 'ascending',
        desc: 'descending',
      }
      return orders[order] || null
    },
    changeIsExtended(value) {
      this.params.isExtended = value
      this.params.offset = 0
      this.$router.replace({ query: this.getQueryParams() })
      this.getList()
    },
    setParamsAfterDelete() {
      if (this.total) {
        this.total -= 1
        if (this.total <= this.params.offset) {
          this.params.offset -= this.params.limit
        }
      }
    },
  },
}
