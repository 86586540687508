import consts from '@/common/consts'
import MixinSearching from '@/mixins/searching'
import Notifications from '@/components/notifications/notifications.vue'

const getDataByType = type => {
  const data = {
    TYPICAL_ACTION: {
      data: {
        actionId: null,
        type: null,
        value: 1,
      },
      id: 'actionId',
      name: 'actionName',
    },
    TARGET_ACHIEVMENT: {
      data: {
        targetId: null,
        oneAfterAnother: false,
        value: 1,
      },
      id: 'targetId',
      name: 'targetName',
    },
    CUSTOM_CURRENCY: {
      data: {
        customCurrencyId: null,
        value: null,
      },
      id: 'customCurrencyId',
      name: 'customCurrencyName',
    },
    QUIZ_COMPLETE: {
      data: {
        quizId: null,
        value: null,
      },
      id: 'quizId',
      name: 'quizName',
    },
  }
  return data[type]
}

export default {
  name: 'target-criterion',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    Notifications,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      criterionCategories: consts.targetCriterionCategories,
      criterionTypicalActionTypes: consts.targetCriterionTypicalActionTypes,
    }
  },
  created() {
    if (!this.value.length) {
      this.add()
    } else {
      this.getLists()
    }
  },
  methods: {
    add() {
      this.search.list = {
        ...this.search.list,
        [this.value.length]: [],
      }

      this.$emit('input', [
        ...this.value,
        {
          required: true,
          comment: null,
          dataType: null,
          data: {},
          rewardIds: [],
          notifications: [],
        },
      ])
    },
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
      delete this.search.list[this.value.length - 1]
    },
    getLists() {
      this.search.list = this.value.reduce((acc, item, index) => ({
        ...acc,
        [index]: [],
      }), {})
      this.value.forEach((c, index) => {
        const data = getDataByType(c.dataType)
        this.search.list[index].push({ id: c.data[data.id], name: c.data[data.name] })
      })
      this.search.list.rewards = this.value.reduce((acc, c) => ([
        ...acc,
        ...c.rewards,
      ]), [])
    },
    selectCategory(criterion, index) {
      this.$set(criterion, 'data', getDataByType(criterion.dataType).data)
      this.search.list[index] = []
    },
  },
}
