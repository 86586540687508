var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chain-targets form-group x1"},[(_vm.value.length)?_c('div',{staticClass:"targets-header"},[_c('div',{staticClass:"target"},[_vm._v("Цель*")]),_c('div',{staticClass:"status"},[_vm._v(" Статус* "),_c('tooltip',[_vm._v(" Статус, при наступлении которого необходимо"),_c('br'),_vm._v(" активировать указанную цель ")])],1)]):_vm._e(),_c('draggable',{staticClass:"form-group drag-list no-top no-bottom",attrs:{"value":_vm.value,"handle":".handle"},on:{"change":_vm.changeOrder}},_vm._l((_vm.value),function(t,index){return _c('div',{key:index,staticClass:"item"},[_c('el-form-item',{staticClass:"draggble handle"},[_c('el-icon',{staticClass:"el-icon-d-caret"})],1),_c('el-form-item',{staticClass:"x1 no-left",attrs:{"error":_vm.errors.first(("chainTargetIds[" + index + "].id")),"show-message":false}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            uniqueIn: [_vm.value, 'id', index],
          }),expression:"{\n            required: true,\n            uniqueIn: [value, 'id', index],\n          }"}],attrs:{"name":("chainTargetIds[" + index + "].id"),"loading":_vm.search.loading,"filterable":"","remote":"","reserve-keyword":"","popper-class":"template-option","placeholder":"Введите название цели","remote-method":function (v) { return _vm.searching(v, 'targets/getList', null, {
            isActive: true,
            chainForTarget: _vm.targetId,
          }); }},on:{"visible-change":function (open) { return open && _vm.searching('', 'targets/getList', null, {
            isActive: true,
            chainForTarget: _vm.targetId,
          }); }},model:{value:(t.id),callback:function ($$v) {_vm.$set(t, "id", $$v)},expression:"t.id"}},_vm._l((_vm.search.list),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id,"disabled":item.chainAvailability && !item.chainAvailability.isAvailable}},[_c('div',{staticClass:"option"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"description"},[_c('span',[_vm._v("ID "+_vm._s(item.id))]),(item.chainAvailability && !item.chainAvailability.isActive)?_c('span',[_vm._v(" "+_vm._s(item.chainAvailability.unavailabilityReason)+" ")]):_vm._e()])])])}),1),_c('popup-error',{attrs:{"error":_vm.errors.first(("chainTargetIds[" + index + "].id"))}})],1),_c('el-form-item',{attrs:{"error":_vm.errors.first(("chainTargetIds[" + index + "].status")),"show-message":false}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":("chainTargetIds[" + index + "].status"),"placeholder":"Выберите статус"},model:{value:(t.status),callback:function ($$v) {_vm.$set(t, "status", $$v)},expression:"t.status"}},_vm._l((_vm.statuses),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value}})}),1),_c('popup-error',{attrs:{"error":_vm.errors.first(("chainTargetIds[" + index + "].status"))}})],1),_c('el-button',{attrs:{"title":"Удалить цель","icon":"el-icon-delete","type":"danger","circle":""},on:{"click":function($event){return _vm.remove(index)}}})],1)}),0),_c('div',{staticClass:"bottom"},[_c('el-button',{staticClass:"add",attrs:{"icon":"el-icon-plus","type":"primary","round":""},on:{"click":_vm.add}},[_vm._v("Добавить цель")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }