import axios from 'axios'
import { EventBus } from './event-bus'

const Http = axios.create({
  withCredentials: false,
  baseURL: '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

Http.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = encodeURI(token)
  }
  return config
}, e => {
  console.log(e)
})

Http.interceptors.response.use(r => r.data.data, e => {
  if (e.response && e.response.data) {
    const error = e.response.data?.error
    if (error && error.code === 'UNAUTHORIZED_REQUEST' && !e.config._retry) {
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) {
        localStorage.removeItem('accessToken')
        return Http.get('auth/refresh', {
          params: { refreshToken },
          _retry: true,
        }).then(data => {
          localStorage.setItem('accessToken', data.accessToken)
          localStorage.setItem('refreshToken', data.refreshToken)
          e.config._retry = true
          return Http.request(e.config)
        }).catch(() => {
          EventBus.$emit('http-error', e)
        })
      }
    } else {
      EventBus.$emit('http-error', e)
    }
  } else {
    EventBus.$emit('http-error', e)
  }
  return Promise.reject(e)
})

export default Http
