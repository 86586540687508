import draggable from 'vuedraggable'
import MixinSearching from '@/mixins/searching'
import Decimal from 'decimal.js'

const reward = {
  rewardId: null,
  probability: 10,
  limit: 1,
  issuedInstanceCount: 0,
}

export default {
  name: 'page-lootbox',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    draggable,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        name: null,
        mnemocode: null,
        title: [],
        description: [],
        attachments: [],
        rewards: [
          { ...reward },
          { ...reward },
        ],
      },
      activeTab: 'common',
    }
  },
  async created() {
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('lootboxes/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      this.form.rewards = this.form.rewards.map(r => ({
        ...r,
        probability: new Decimal(r.probability).times(100).toNumber(),
        limit: r.rewardType === 'BONUSES' ? null : (r.limit || 1),
      }))
      this.search.list = this.data.rewards.map(r => ({ id: r.rewardId, name: r.rewardName }))
      this.loading.form = false
    }
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`lootboxes/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData({
              ...this.form,
              rewards: this.form.rewards.map((r, index) => ({
                rewardId: r.rewardId,
                order: index + 1,
                probability: new Decimal(r.probability).times(0.01).toNumber(),
                limit: r.limit,
              })),
            }),
          }).then(() => {
            this.$message({
              message: `Ящик ${this.id ? 'отредактирован' : 'создан'}`,
              type: 'success',
            })
            this.$router.push({ name: 'lootboxes' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('lootboxes/delete', this.data.id).then(() => {
        this.$router.push({ name: 'lootboxes' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('lootboxes/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'lootboxes', query: this.$route.query.from })
    },
    addReward() {
      this.form.rewards.push({ ...reward })
    },
    removeReward(index) {
      this.form.rewards.splice(index, 1)
    },
    validateRewards() {
      this.$nextTick(() => {
        this.form.rewards.forEach((r, i) => {
          if (this.fields[`rewards[${i}].probability`]) {
            this.$validator.validate(`rewards[${i}].probability`)
          }
        })
      })
    },
    selectReward(id) {
      const reward = this.search.list.find(r => r.id === id)
      if (reward) {
        this.form.rewards.forEach(r => {
          if (r.rewardId === id) {
            r.rewardType = reward.type
            r.limit = reward.type === 'BONUSES' ? null : r.limit
          }
        })
      }
    },
  },
}
