import MixinForm from '@/mixins/form'
import MixinSearching from '@/mixins/searching'
import consts from '@/common/consts'

export default {
  name: 'form-add-member-to-blacklist',
  mixins: [
    MixinForm,
    MixinSearching,
  ],
  data() {
    return {
      form: {
        profileId: null,
        comment: null,
        reason: null,
        start: null,
        end: null,
      },
      reasons: consts.blacklistReasons,
    }
  },
}
