import MixinList from '@/mixins/list'

export default {
  name: 'page-lootboxes',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'lootboxes',
        messages: {
          save: 'Ящик сохранен',
          remove: 'Ящик удален',
          restore: 'Ящик восстановлен',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
      },
    }
  },
}
