import MixinForm from '@/mixins/form'
import { mapState } from 'vuex'

export default {
  name: 'form-entrypoint',
  mixins: [
    MixinForm,
  ],
  data() {
    return {
      form: {
        frontofficeId: null,
        isDeleted: false,
        title: null,
        mnemocode: null,
        delayDeactivatingIsAllow: false,
      },
    }
  },
  computed: {
    ...mapState({
      tree: state => state.accessObjects.tree,
    }),
    frontoffice() {
      const ao = this.$utils.findAccessObject(this.data.frontofficeId, 'FRONTOFFICE', this.tree)
      return ao ? { title: ao.title, id: ao.id, brandId: ao.brandId } : {}
    },
    brand() {
      const ao = this.$utils.findAccessObject(this.frontoffice.brandId, 'BRAND', this.tree)
      return ao ? { title: ao.title, id: ao.id } : {}
    },
  },
}
