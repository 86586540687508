import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment'
import ElementUI from 'element-ui'
import VeeValidate from 'vee-validate'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import ClickOutside from 'vue-click-outside'
import VueMask from 'v-mask'
import vvParams from './plugins/vee-validate'
import App from './app/app.vue'
import router from './router'
import store from './store'
import filters from './plugins/filters'
import utils from './plugins/utils'
import guard from './plugins/guard'
import masks from './plugins/masks'
import PageHeader from './components/page-header/page-header.vue'
import BtnDelete from './components/ui/btn-delete/btn-delete.vue'
import DatePicker from './components/ui/date-picker/date-picker.vue'
import Localizations from './components/localizations/localizations.vue'
import PopupError from './components/ui/popup-error/popup-error.vue'
import Attachments from './components/attachments/attachments.vue'
import UploadImage from './components/upload-image/upload-image.vue'
import InputSearch from './components/ui/input-search/input-search.vue'
import SelectTimezones from './components/ui/select-timezones/select-timezones.vue'
import Tooltip from './components/ui/tooltip/tooltip.vue'
import './assets/styles/common.scss'

Vue.config.productionTip = false
moment.locale('ru')

Vue.use(VeeValidate, vvParams)
Vue.use(VueMoment, { moment })
Vue.use(filters)
Vue.use(utils)
Vue.use(guard)
Vue.use(masks)
Vue.use(ElementUI, { locale })
Vue.use(VueMask)

Vue.component('page-header', PageHeader)
Vue.component('btn-delete', BtnDelete)
Vue.component('date-picker', DatePicker)
Vue.component('localizations', Localizations)
Vue.component('popup-error', PopupError)
Vue.component('attachments', Attachments)
Vue.component('upload-image', UploadImage)
Vue.component('input-search', InputSearch)
Vue.component('select-timezones', SelectTimezones)
Vue.component('tooltip', Tooltip)

Vue.directive('clickOutside', ClickOutside)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
