import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('notificationTemplates', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`notificationTemplates/${id}`).then(r => (
        {
          ...r,
          image: utils.getCdnImage(r.image, 400, 400),
        }
      ))
    },
    create({ rootState }, { data }) {
      return Http.post('notificationTemplates', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`notificationTemplates/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`notificationTemplates/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`notificationTemplates/restore/${id}`)
    },
    getVariables(ctx, entity) {
      return Http.get(`notificationTemplates/variables/${entity}`).then(r => {
        return r.list
      })
    },
  },
})
