import MixinForm from '@/mixins/form'
import MixinSearching from '@/mixins/searching'

export default {
  name: 'form-add-users',
  mixins: [
    MixinForm,
    MixinSearching,
  ],
  data() {
    return {
      form: {
        userIds: [],
      },
    }
  },
}
