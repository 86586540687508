import MixinList from '@/mixins/list'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'page-groups',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'groups',
        messages: {
          save: 'Группа пользователей сохранена',
          remove: 'Группа пользователей удалена',
          restore: 'Группа пользователей восстановлена',
        },
      },
      params: {
        search: '',
        sort: '',
        direction: '',
        isAdmin: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      accessObject: 'accessObjects/current',
    }),
    ...mapState({
      user: state => state.user.data,
    }),
    types() {
      return [
        { title: 'Группы администраторов', value: true, disabled: false },
        { title: 'Группы объекта доступа', value: false, disabled: !this.accessObject },
      ]
    },
  },
  watch: {
    accessObject: {
      immediate: true,
      handler(a) {
        if (!a && this.user.isAdmin) {
          this.params.isAdmin = true
        }
      },
    },
  },
}
