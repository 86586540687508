export default {
  accessObjectTypes: [
    { title: 'Бренд', value: 'BRAND', many: 'brands' },
    { title: 'Фронтофис', value: 'FRONTOFFICE', many: 'frontoffices' },
    { title: 'Точка входа', value: 'ENTRYPOINT', many: 'entrypoints' },
  ],
  targetCriterionCategories: [
    { title: 'Выполнение типового действия', value: 'TYPICAL_ACTION' },
    { title: 'Достижение цели', value: 'TARGET_ACHIEVMENT' },
    { title: 'Получение кастомной валюты', value: 'CUSTOM_CURRENCY' },
    { title: 'Прохождение теста', value: 'QUIZ_COMPLETE' },
  ],
  targetCriterionTypicalActionTypes: [
    { title: 'Выполнение определенного количества типовых действий', value: 'QUANTITY_ORIENTED' },
    { title: 'Выполнение типового действия с определенным показателем', value: 'COUNT_ORIENTED' },
  ],
  measurementUnits: [
    { title: 'Метр', value: 'METER' },
    { title: 'Калория', value: 'CALORIES' },
    { title: 'Шаг', value: 'STEP' },
    { title: 'Минута', value: 'MOVE_MINUTES' },
    { title: 'Сумма покупок (в валюте бренда торговой сети в рамках страны)', value: 'AMOUNT_PURCHASES' },
  ],
  attachmentTypes: [
    { title: 'Изображение', value: 'IMAGE' },
    { title: 'Цвет', value: 'COLOR' },
    { title: 'Градиент', value: 'GRADIENT' },
    { title: 'Видео', value: 'VIDEO' },
    { title: 'Статическая страница из CMS Gate', value: 'STATIC_PAGE' },
    { title: 'Баннер из CMS Gate', value: 'BANNER' },
    { title: 'Дата и время', value: 'DATETIME' },
    { title: 'Набор локализованных строк', value: 'LOCALIZED_STRING_SET' },
    { title: 'Lottie анимация', value: 'LOTTIE' },
    { title: 'Диплинк', value: 'DEEPLINK' },
  ],
  rewardChangingTypes: [
    { title: 'Присвоить следующий уровень', value: 'NEXT' },
    { title: 'Присвоить конкретный уровень', value: 'DEFINITE' },
  ],
  blacklistReasons: [
    { title: 'Мошенничество', value: 'FRAUD' },
  ],
  lotteryStatuses: [
    { title: 'Ожидание запуска розыгрыша', value: 'AWAITING_LAUNCH' },
    { title: 'Розыгрыш запущен, идёт трансляция', value: 'STREAMING_NOW' },
    { title: 'Розыгрыш завершен', value: 'COMPLETED' },
  ],
  notificationTemplateEntities: [
    { title: 'Для целей и критериев', value: 'TARGET' },
    { title: 'Для наград', value: 'REWARD' },
  ],
  permissionLevels: {
    NONE: 0,
    READER: 1,
    WRITER: 2,
    OWNER: 3,
  },
}
