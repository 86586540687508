import UploadFile from '@/components/upload-file/upload-file.vue'

export default {
  name: 'form-import-reward-instances',
  inject: ['$validator'],
  components: {
    UploadFile,
  },
  props: [
    'loading',
    'name',
  ],
  data() {
    return {
      form: {
        file: null,
      },
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.$emit('submit', this.form)
        }
      })
    },
  },
}
