import consts from '@/common/consts'
import MixinList from '@/mixins/list'
import Priority from '@/components/ui/priority/priority.vue'

export default {
  name: 'page-notification-templates',
  mixins: [
    MixinList,
  ],
  components: {
    Priority,
  },
  data() {
    return {
      entity: {
        name: 'notificationTemplates',
        messages: {
          save: 'Шаблон уведомления сохранен',
          remove: 'Шаблон уведомления удален',
          restore: 'Шаблон уведомления восстановлен',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
        entity: null,
      },
      entities: consts.notificationTemplateEntities,
    }
  },
}
