import MixinList from '@/mixins/list'
import FormAddMemberToBlacklist from '@/components/forms/form-add-member-to-blacklist/form-add-member-to-blacklist.vue'

export default {
  name: 'page-members-blacklist',
  mixins: [
    MixinList,
  ],
  components: {
    FormAddMemberToBlacklist,
  },
  data() {
    return {
      entity: {
        name: 'members',
        getList: 'getBlackList',
      },
      params: {
        search: '',
        sort: '',
        direction: '',
      },
    }
  },
  methods: {
    remove(data) {
      this.loading.remove = data.id
      this.$store.dispatch('members/removeFromBlacklist', data.id).then(() => {
        this.$message({
          message: 'Блокировка удалена',
          type: 'success',
        })
        this.loading.remove = false
        this.getList()
      }).finally(() => {
        this.loading.remove = false
      })
    },
    addMembers(data) {
      this.loading.modal = true
      this.$store.dispatch('members/addToBlacklist', data).then(() => {
        this.item.show = false
        this.$message({
          message: 'Участник добавлен в черный список',
          type: 'success',
        })
        this.getList()
      }).finally(() => {
        this.loading.modal = false
      })
    },
  },
}
