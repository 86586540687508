import { mapGetters, mapState } from 'vuex'

export default {
  name: 'page-group',
  inject: ['$validator'],
  data() {
    return {
      data: null,
      levels: [],
      entities: [],
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        title: null,
        permissions: [],
        isAdmin: false,
      },
      groups: [
        {
          title: 'Объекты доступа и настройка прав',
          entities: [
            'BRAND', 'FRONTOFFICE', 'ENTRYPOINT', 'GROUP', 'USER',
          ],
        },
        {
          title: 'Цели и награды',
          entities: [
            'TARGET', 'REWARD', 'REWARD_INSTANCE', 'CUSTOM_CURRENCY', 'LOOT_BOX', 'LOTTERY',
          ],
        },
        {
          title: 'Тесты',
          entities: [
            'QUIZ', 'QUESTION', 'QUESTION_ANSWER',
          ],
        },
        {
          title: 'Участники',
          entities: [
            'PROFILE_BLACK_LIST', 'PROFILE_WHITE_LIST',
          ],
        },
      ],
    }
  },
  created() {
    this.loading.form = true
    const requests = [
      this.$store.dispatch('common/getPermissionLevels'),
      this.$store.dispatch('common/getPermissionEntities'),
    ]
    if (this.id) {
      requests.push(this.$store.dispatch('groups/getById', this.id))
    }
    Promise.all(requests).then(([levels, entities, group]) => {
      this.levels = levels
      this.entities = entities
      this.data = group || null
      if (group) {
        this.$utils.fillForm(group, this.form)
      }
      const permissions = this.id ? this.data.permissions : this.defaultPermissions
      this.form.permissions = this.getPermissionObject(permissions)
      this.form.isAdmin = !this.accessObject
    }).finally(() => {
      this.loading.form = false
    })
  },
  computed: {
    ...mapGetters({
      accessObject: 'accessObjects/current',
    }),
    ...mapState({
      user: state => state.user.data,
    }),
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
    defaultPermissions() {
      return this.entities.map(e => ({
        entity: e.value,
        permissionLevel: 'READER',
      }))
    },
    groupEntities() {
      const defined = this.groups.reduce((acc, item) => ([
        ...acc,
        ...item.entities,
      ]), [])
      const others = this.entities.map(p => p.value).filter(e => !defined.includes(e))
      let groups = [
        ...this.groups,
        {
          title: 'Разное',
          entities: others,
        },
      ]
      groups = groups.map(g => ({
        ...g,
        entities: g.entities.map(ge => ({
          ...this.entities.find(e => e.value === ge),
        })),
      }))
      return groups
    },
  },
  methods: {
    getPermissionObject(permissions) {
      return permissions.reduce((acc, item) => {
        return {
          ...acc,
          [item.entity]: item.permissionLevel,
        }
      }, {})
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`groups/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData({
              ...this.form,
              permissions: Object.keys(this.form.permissions).map(entity => ({
                entity,
                permissionLevel: this.form.permissions[entity],
              })),
            }),
          }).then(group => {
            this.$message({
              message: `Группа ${this.id ? 'отредактирована' : 'создана'}`,
              type: 'success',
            })
            this.$router.push({
              name: 'group-users',
              params: { id: group.id },
            })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('groups/delete', this.data.id).then(() => {
        this.$router.push({ name: 'groups' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('groups/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'groups', query: this.$route.query.from })
    },
  },
}
