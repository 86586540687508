import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import users from './users'
import accessObjects from './access-objects'
import targets from './targets'
import common from './common'
import groups from './groups'
import typicalActions from './typical-actions'
import customCurrencies from './custom-currencies'
import rewards from './rewards'
import rewardInstances from './reward-instances'
import quizes from './quizes'
import questions from './questions'
import lootboxes from './lootboxes'
import lotteries from './lotteries'
import members from './members'
import notificationTemplates from './notification-templates'
import files from './files'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    users,
    accessObjects,
    targets,
    common,
    groups,
    typicalActions,
    customCurrencies,
    rewards,
    rewardInstances,
    quizes,
    questions,
    lootboxes,
    lotteries,
    members,
    notificationTemplates,
    files,
  },
})
