var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-rewards"},[_c('page-header',{attrs:{"title":"Награды","add-title":"Создать награду","show-add":_vm.$guard.hasPermission('REWARD', 'WRITER')},on:{"add":function($event){return _vm.$router.push({
      name: 'reward',
      params: { id: 'create' },
      query: {
        from: _vm.$route.query,
      }
    })}}},[_c('template',{slot:"actions"},[_c('el-switch',{attrs:{"inactive-text":"Показывать удаленные"},on:{"input":_vm.changeIsExtended},model:{value:(_vm.params.isExtended),callback:function ($$v) {_vm.$set(_vm.params, "isExtended", $$v)},expression:"params.isExtended"}})],1)],2),_c('div',{staticClass:"filters"},[_c('input-search',{on:{"search":_vm.changeFilters},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),_c('el-select',{attrs:{"placeholder":"Активность","clearable":""},on:{"change":_vm.changeFilters},model:{value:(_vm.params.isActive),callback:function ($$v) {_vm.$set(_vm.params, "isActive", $$v)},expression:"params.isActive"}},_vm._l((_vm.activities),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value}})}),1),_c('el-select',{staticClass:"select-type",attrs:{"placeholder":"Тип","clearable":""},on:{"change":_vm.changeFilters},model:{value:(_vm.params.type),callback:function ($$v) {_vm.$set(_vm.params, "type", $$v)},expression:"params.type"}},_vm._l((_vm.types),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value}})}),1)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.table),expression:"loading.table"}],staticClass:"table-wrapper"},[(!_vm.loading.table)?_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"default-sort":{
        prop: _vm.params.sort,
        order: _vm.getOrder(),
      },"row-key":"id"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Название","sortable":"custom","prop":"name"}}),_c('el-table-column',{attrs:{"label":"Мнемокод","sortable":"custom","prop":"mnemocode","width":"200"}}),_c('el-table-column',{attrs:{"label":"Тип"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("rewardType")(scope.row.type))+" ")]}}],null,false,237396691)}),_c('el-table-column',{attrs:{"label":"Активность","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.isActive)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("Неактивна")]):_c('el-tag',{attrs:{"type":"success"}},[_vm._v("Активна")])]}}],null,false,992150267)}),_c('el-table-column',{attrs:{"label":"Порядок","prop":"order","width":"90"}}),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.notificationEnabled)?_c('i',{staticClass:"el-icon-message-solid",staticStyle:{"font-size":"16px"},attrs:{"title":"Уведомления включены"}}):_vm._e()]}}],null,false,1958096912)}),_c('el-table-column',{attrs:{"align":"right","width":"200","class-name":"actions"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(['PROMOCODE', 'EGC'].includes(scope.row.type)
              && _vm.$guard.hasPermission('REWARD_INSTANCE', 'READER'))?_c('el-button',{attrs:{"icon":"el-icon-files","circle":"","title":"Экземпляры награды"},on:{"click":function($event){return _vm.$router.push({
              name: 'reward-instances',
              params: { id: scope.row.id },
              query: {
                from: _vm.$route.query,
              }
            })}}}):_vm._e(),_c('el-button',{attrs:{"icon":"el-icon-edit","circle":"","title":"Редактировать награду"},on:{"click":function($event){return _vm.$router.push({
              name: 'reward',
              params: { id: scope.row.id },
              query: {
                from: _vm.$route.query,
              }
            })}}}),(_vm.$guard.hasPermission('REWARD', 'OWNER'))?_c('btn-delete',{attrs:{"is-deleted":scope.row.isDeleted,"loading":_vm.loading.remove === scope.row.id,"title-delete":"Удалить награду","confirm-text":"Вы действительно хотите удалить награду?"},on:{"submit":function($event){scope.row.isDeleted ? _vm.restore(scope.row) : _vm.remove(scope.row)}}}):_vm._e()]}}],null,false,1091043481)})],1):_vm._e()],1),_c('el-row',{staticStyle:{"padding":"16px 0"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.page,"page-size":_vm.params.limit,"hide-on-single-page":""},on:{"current-change":_vm.changePagination}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }