export default {
  name: 'Login',
  inject: ['$validator'],
  data() {
    return {
      form: {
        email: '',
        password: '',
        // email: 'a.efremov@headsandhands.ru',
        // password: 'mM!1111111111',
      },
      loading: false,
      rules: {
        login: [
          { required: true },
        ],
        password: [
          { required: true },
        ],
      },
    }
  },
  methods: {
    login() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/auth', this.form).then(() => {
            this.$router.push({ name: 'access-objects' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
            if (e.response?.data?.error?.code === 'AUTH_CREDENTIALS_INVALID') {
              this.errors.add({ field: 'password', msg: 'Неверный email или пароль' })
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
