import MixinForm from '@/mixins/form'
import MixinSearching from '@/mixins/searching'

export default {
  name: 'form-user',
  mixins: [
    MixinForm,
    MixinSearching,
  ],
  data() {
    return {
      form: {
        login: null,
        password: null,
        passwordConfirm: null,
        givenName: null,
        surname: null,
        companyName: null,
        jobTitle: null,
        avatar: {
          gradient: {
            colorStart: '#252525',
            colorStop: '#AFAFAF',
          },
          imageUri: null,
        },
        groupIds: [],
      },
    }
  },
  async created() {
    if (this.data.id) {
      const data = await this.$store.dispatch('users/getById', this.data.id)
      this.$utils.fillForm(data, this.form)
      this.form.groupIds = data.groups.map(g => g.id)
      this.search.list = data.groups.map(g => ({ id: g.id, title: g.name }))
    }
  },
}
