import draggable from 'vuedraggable'
import consts from '@/common/consts'
import MixinSearching from '@/mixins/searching'
import Notifications from '@/components/notifications/notifications.vue'

const newLevel = order => ({
  order,
  levelCode: null,
  persgateMnemocode: null,
})

const mapDataFields = type => {
  const fields = {
    BONUSES: {
      data: {
        amount: 1,
        bonusTypeCode: null,
      },
    },
    CUSTOM_CURRENCY: {
      data: {
        amount: 1,
        customCurrencyId: null,
      },
      name: 'customCurrencyName',
      id: 'customCurrencyId',
    },
    LOOT_BOX: {
      data: {
        amount: 1,
        lootBoxId: null,
      },
      name: 'lootBoxName',
      id: 'lootBoxId',
    },
    LOTTERY: {
      data: {
        lotteryId: null,
      },
      name: 'lotteryName',
      id: 'lotteryId',
    },
    CLUB_PROGRAMM_LEVEL: {
      data: {
        changingType: 'NEXT',
        definiteLevelCode: null,
        availableLevels: [newLevel(1)],
      },
    },
  }
  return fields[type] || {}
}

export default {
  name: 'page-reward',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    draggable,
    Notifications,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        isActive: false,
        name: null,
        mnemocode: null,
        order: 0,
        type: null,
        partnershipReward: false,
        persgateType: null,
        validity: null,
        activityDelay: null,
        data: {},
        title: [],
        description: [],
        attachments: [],
        notifications: [],
        validityActivityOffsetSource: null,
        validityActivityOffset: null,
      },
      types: [],
      changingTypes: consts.rewardChangingTypes,
      activeTab: 'common',
      instanceTypes: ['PROMOCODE', 'EGC'],
    }
  },
  async created() {
    this.loading.form = true
    this.types = await this.$store.dispatch('rewards/getTypes')
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('rewards/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      const data = mapDataFields(this.data.type)
      if (data.name && data.id) {
        this.search.list = [{
          id: this.data.data[data.id],
          name: this.data.data[data.name],
        }]
      }
    }
    this.loading.form = false
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
    levels() {
      if (this.form.data.availableLevels) {
        return this.form.data.availableLevels
          .filter((l, index) => {
            const levelCode = this.fields[`data.availableLevels[${index}].levelCode`]
            const mnemocode = this.fields[`data.availableLevels[${index}].persgateMnemocode`]
            return levelCode?.valid && mnemocode?.valid
          })
          .map(l => l.levelCode)
      }
      return []
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`rewards/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData(this.form),
          }).then(reward => {
            this.$message({
              message: `Награда ${this.id ? 'отредактирована' : 'создана'}`,
              type: 'success',
            })
            if (this.instanceTypes.includes(this.form.type) && !this.id) {
              this.$router.push({
                name: 'reward-instances',
                params: { id: reward.id },
              })
            } else {
              this.$router.push({ name: 'rewards' })
            }
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('rewards/delete', this.data.id).then(() => {
        this.$router.push({ name: 'rewards' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('rewards/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'rewards', query: this.$route.query.from })
    },
    selectType(type) {
      this.$nextTick(() => {
        this.$set(this.form, 'data', mapDataFields(type).data || {})
        this.search.list = []
      })
    },
    addLevel() {
      this.form.data.availableLevels.push({ ...newLevel(this.form.data.availableLevels.length + 1) })
    },
    removeLevel(index) {
      if (this.form.data.availableLevels[index].levelCode === this.form.data.definiteLevelCode) {
        this.form.data.definiteLevelCode = null
      }
      this.form.data.availableLevels.splice(index, 1)
    },
    refreshOrder() {
      this.form.data.availableLevels = this.form.data.availableLevels.map((l, i) => ({
        ...l,
        order: i + 1,
      }))
    },
    changeLevelCode() {
      const definiteCode = this.form.data.definiteLevelCode
      if (definiteCode && !this.levels.some(l => {
        return l.toString() === definiteCode.toString()
      })) {
        this.form.data.definiteLevelCode = null
      }
    },
    changeValidityActivityOffsetSource(value) {
      if (value !== 'CURRENT_ENTITY') {
        this.form.validityActivityOffset = null
      } else {
        this.$validator.reset('validityActivityOffset')
      }
    },
  },
}
