import MixinForm from '@/mixins/form'
import MixinSearching from '@/mixins/searching'

export default {
  name: 'form-target-activation',
  mixins: [
    MixinForm,
    MixinSearching,
  ],
  data() {
    return {
      form: {
        profileIds: [],
        allProfiles: true,
      },
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.$emit('submit', {
            profileIds: this.form.allProfiles ? null : this.form.profileIds,
          })
        }
      })
    },
  },
}
