import MixinList from '@/mixins/list'

export default {
  name: 'page-custom-currencies',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'customCurrencies',
        messages: {
          save: 'Кастомную валюту отредактирована',
          remove: 'Кастомная валюта удалена',
          restore: 'Кастомная валюта восстановлена',
        },
      },
    }
  },
}
