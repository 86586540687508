import camelCase from 'lodash/camelCase'
import { dot } from 'dot-object'
import { serialize } from 'object-to-formdata'
import sha1 from 'js-sha1'
import cloneDeep from 'lodash/cloneDeep'
import consts from '@/common/consts'

export default {
  camelToUpper: str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).toUpperCase(),
  upperToCamel: str => camelCase(str),
  serialize: data => {
    return serialize(dot(data))
  },
  addServerErrors(e, errors) {
    if (e.response && e.response.status === 400) {
      e = e.response
      if (e.data.error && e.data.error.code === 'VALIDATION_FIELDS_ERROR') {
        Object.keys(e.data.error.fields).forEach(field => {
          errors.add({ field, msg: e.data.error.fields[field] })
        })
      }
    }
    setTimeout(() => {
      this.scrollToError()
    }, 500)
  },
  scrollToError(tabs = null) {
    tabs = tabs || document.querySelector('form .el-tabs')
    if (tabs) {
      const el = tabs.querySelector('.is-error')
      if (el) {
        const name = el.closest('div[role="tabpanel"]')
          .getAttribute('id')
          .split('-')[1]
        tabs.querySelector(`#tab-${name}`).click()
      }
    }
    setTimeout(() => {
      const el = document.querySelector('.is-error')
      if (el) {
        const top = el.getBoundingClientRect().top + window.scrollY - (window.innerHeight / 2 - 150)
        window.scrollTo(0, top)
      }
    }, 500)
  },
  prepareQuery(query) {
    const result = {}
    const mapping = {
      true: true,
      false: false,
    }
    Object.keys(query).forEach(field => {
      const value = query[field]
      result[field] = /^(\d+|\d*\.\d+)$/.test(value) ? parseFloat(value) : value
      result[field] = mapping[result[field]] !== undefined ? mapping[result[field]] : result[field]
    })
    return result
  },
  getParams(params) {
    return Object.keys(params).reduce((acc, field) => {
      if (params[field]) {
        if (Array.isArray(params[field])) {
          if (params[field].length) {
            acc[field] = params[field].join(',')
          }
        } else {
          acc[field] = params[field]
        }
      }

      return acc
    }, {})
  },
  getAccessObjectType(value) {
    return consts.accessObjectTypes.find(i => i.value === value)
  },
  findAccessObject(id, type, tree) {
    let result = null
    const find = group => {
      group.forEach(item => {
        if (item.id === id && item.type === type) {
          result = item
        } else if (item.children) {
          find(item.children)
        }
      })
    }
    find(tree)
    return result
  },
  accessObjectsHeaders(state) {
    if (state.accessObjects.id && state.accessObjects.type) {
      return {
        AccessObjectId: state.accessObjects.id,
        AccessObjectType: state.accessObjects.type,
      }
    }
    return {}
  },
  fillForm(data, form) {
    data = cloneDeep(data)
    const correctValue = (field, value) => {
      let result
      switch (field) {
        case 'deadline':
        case 'activityDelay':
        case 'validity':
        case 'delay':
          result = Math.ceil(value / 3600)
          break
        case 'notifications':
          result = value.map(n => ({
            ...n,
            delay: n.delay ? Math.ceil(n.delay / 60) : n.delay,
          }))
          break
        case 'criterions':
          result = value.map(c => ({
            ...c,
            notifications: c.notifications.map(n => ({
              ...n,
              delay: n.delay ? Math.ceil(n.delay / 60) : n.delay,
            })),
          }))
          break
        default:
          result = value
      }
      return result
    }
    Object.keys({ ...data }).forEach(field => {
      if (form[field] !== undefined && data[field] !== null) {
        form[field] = correctValue(field, data[field])
      }
    })
  },
  prepareFormData(data) {
    const correctValue = (field, value) => {
      let result
      switch (field) {
        case 'deadline':
        case 'activityDelay':
        case 'validity':
        case 'delay':
          result = value ? value * 3600 : value
          break
        case 'notifications':
          result = value.map(n => ({
            ...n,
            delay: n.delay ? n.delay * 60 : n.delay,
          }))
          break
        case 'criterions':
          result = value.map(c => ({
            ...c,
            notifications: c.notifications.map(n => ({
              ...n,
              delay: n.delay ? n.delay * 60 : n.delay,
            })),
          }))
          break
        default:
          result = value
      }
      if (result === '') {
        result = null
      }

      return result
    }
    return Object.keys(data).reduce((acc, field) => ({
      ...acc,
      [field]: correctValue(field, data[field]),
    }), {})
  },
  isAuthError(e) {
    return ['UNAUTHORIZED_REQUEST', 'ENCODED_TOKEN_INVALID'].includes(e?.data?.error?.code)
  },
  serverError(status, data) {
    return {
      status,
      response: { data },
    }
  },
  getCdnImageUri(uri, width, height) {
    if (uri) {
      const filename = uri.split('/').pop()
      const hash = sha1(`media_content${width}${height}${filename}sportmaster404`.toLowerCase())
        .substring(0, 4)
        .toLowerCase()
      return uri
        .replace('{width}_{height}', `${width}_${height}`)
        .replace('{hash}', hash)
    }
    return null
  },
  getAttachmentsWithCdnImages(a) {
    return a.map(i => {
      if (i.type === 'IMAGE') {
        i.value.imageUri = this.getCdnImageUri(i.value.imageUri, 400, 400)
      }
      return i
    })
  },
  getCdnImage(field, width, height) {
    if (field && field.imageUri) {
      return {
        ...field,
        imageUri: this.getCdnImageUri(field.imageUri, width, height),
      }
    }
    return null
  },
}
