export default [
  {
    label: 'GMT-11:00',
    utc: '-11:00',
  },
  {
    label: 'GMT-10:00',
    utc: '-10:00',
  },
  {
    label: 'GMT-09:30',
    utc: '-09:30',
  },
  {
    label: 'GMT-09:00',
    utc: '-09:00',
  },
  {
    label: 'GMT-08:00',
    utc: '-08:00',
  }, {
    label: 'GMT-07:00',
    utc: '-07:00',
  },
  {
    label: 'GMT-06:00',
    utc: '-06:00',
  },
  {
    label: 'GMT-05:00',
    utc: '-05:00',
  },
  {
    label: 'GMT-04:00',
    utc: '-04:00',
  },
  {
    label: 'GMT-03:30',
    utc: '-03:30',
  },
  {
    label: 'GMT-03:00',
    utc: '-03:00',
  },
  {
    label: 'GMT-02:00',
    utc: '-02:00',
  },
  {
    label: 'GMT-01:00',
    utc: '-01:00',
  },
  {
    label: 'GMT+00:00',
    utc: '+00:00',
  },
  {
    label: 'GMT+01:00',
    utc: '+01:00',
  },
  {
    label: 'GMT+02:00',
    utc: '+02:00',
  },
  {
    label: 'GMT+03:00',
    utc: '+03:00',
  },
  {
    label: 'GMT+03:30',
    utc: '+03:30',
  },
  {
    label: 'GMT+04:00',
    utc: '+04:00',
  },
  {
    label: 'GMT+04:30',
    utc: '+04:30',
  },
  {
    label: 'GMT+05:00',
    utc: '+05:00',
  },
  {
    label: 'GMT+05:30',
    utc: '+05:30',
  },
  {
    label: 'GMT+05:45',
    utc: '+05:45',
  },
  {
    label: 'GMT+06:00',
    utc: '+06:00',
  },
  {
    label: 'GMT+06:30',
    utc: '+06:30',
  },
  {
    label: 'GMT+07:00',
    utc: '+07:00',
  },
  {
    label: 'GMT+08:00',
    utc: '+08:00',
  },
  {
    label: 'GMT+08:45',
    utc: '+08:45',
  },
  {
    label: 'GMT+09:00',
    utc: '+09:00',
  },
  {
    label: 'GMT+09:30',
    utc: '+09:30',
  },
  {
    label: 'GMT+10:00',
    utc: '+10:00',
  },
  {
    label: 'GMT+10:30',
    utc: '+10:30',
  },
  {
    label: 'GMT+11:00',
    utc: '+11:00',
  },
  {
    label: 'GMT+12:00',
    utc: '+12:00',
  },
  {
    label: 'GMT+12:45',
    utc: '+12:45',
  },
  {
    label: 'GMT+13:00',
    utc: '+13:00',
  },
  {
    label: 'GMT+14:00',
    utc: '+14:00',
  },
]
