import ru from 'vee-validate/dist/locale/ru'
import { Validator } from 'vee-validate'
import moment from 'moment/moment'
import mimes from 'mime-types'
import Decimal from 'decimal.js'

const format = 'yyyy-MM-DDTHH:mm:ss'

Validator.extend('datetime', {
  validate: value => {
    return moment(value, format).isValid()
  },
})

Validator.extend('mnemocode', {
  validate: value => {
    return /^[a-zA-Z_0-9]+$/g.test(value)
  },
})

Validator.extend('password', {
  validate: value => {
    return /(?=.*[0-9])(?=.*[!@#$-.%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$-.%^&*]{12,}/g.test(value)
  },
})

Validator.extend('color', {
  validate: value => {
    return /^[#][0-9a-fA-F]{6}$/g.test(value)
  },
})

Validator.extend('uri', {
  validate: value => {
    return /\w+:(\/?\/?)[^\s]+/g.test(value)
  },
})

Validator.extend('string', {
  validate: value => {
    return /^[\p{L}\s\d\p{Po}\p{S}\p{Pd}\p{Pi}\p{Pf}]*$/gu.test(value)
  },
})

Validator.extend('afterDatetime', {
  validate: (value, [date]) => {
    return !date || moment(value, format).isAfter(moment(date, format))
  },
})

Validator.extend('isSameOrBeforeDatetime', {
  validate: (value, [date]) => {
    return !date || moment(value, format).isSameOrBefore(moment(date, format))
  },
})

Validator.extend('latitude', {
  validate: value => {
    return !Number.isNaN(value) && Math.abs(value) <= 90
  },
})

Validator.extend('longitude', {
  validate: value => {
    return !Number.isNaN(value) && Math.abs(value) <= 180
  },
})

Validator.extend('requiredIf', {
  validate: (value, [fieldValue]) => {
    return !(!value && !!fieldValue)
  },
}, {
  hasTarget: true,
})

Validator.extend('sameDay', {
  validate: (value, [date]) => {
    return moment(value).isSame(date, 'day')
  },
}, {
  hasTarget: true,
})

Validator.extend('notMore', {
  validate: (value, [value1]) => {
    return Number(value) <= Number(value1)
  },
}, {
  hasTarget: true,
})

Validator.extend('samePasswords', {
  validate: (value, [confirm]) => {
    return value === confirm
  },
})

Validator.extend('maxFilesSize', {
  validate: (value, [maxSize]) => {
    return (value.reduce((acc, file) => {
      return acc + file.size
    }, 0) / 1024 / 1024) <= maxSize
  },
})

Validator.extend('maxFileSize', {
  validate: ([value], [maxSize]) => {
    return value.size / 1024 / 1024 <= maxSize
  },
})

Validator.extend('extensions', {
  validate: ([value], extensions) => {
    if (Array.isArray(value)) {
      return value.reduce((acc, file) => {
        if (!extensions.includes(mimes.extension(file.type))) {
          acc = false
        }

        return acc
      }, true)
    } else {
      return extensions.includes(mimes.extension(value.type))
    }
  },
})

Validator.extend('uniqueIn', {
  validate: (value, [arr, field, index]) => {
    const a = arr.filter((v, i) => i !== index && !!v[field])
    return !a.some(v => v[field].toString() === value.toString())
  },
})

Validator.extend('probabilitySum', {
  validate: (value, arr) => {
    const sum = arr.reduce((acc, item) => {
      return new Decimal(acc).plus(item.probability)
    }, new Decimal(0))
    return sum.toNumber() === 100
  },
}, {
  hasTarget: true,
})

export default {
  inject: false,
  locale: 'ru',
  dictionary: {
    ru: {
      ...ru,
      messages: {
        ...ru.messages,
        required: () => 'Поле обязательно для заполнения.',
        confirmed: () => 'Поля не совпадают.',
        mimes: (file, types) => {
          const formats = types.map(item => item.split('/')[1])
          return `Неверный формат файла. Допустимые форматы: ${formats.join(', ')}`
        },
        size: (file, size) => {
          return `Размер файла должен быть не болеее ${size} МБ`
        },
        datetime: () => 'Неверный формат даты и времени',
        afterDatetime: (field, params) => {
          return `Дата должна быть позже даты в поле "${params[1]}"`
        },
        isSameOrBeforeDatetime: (field, params) => {
          return `Дата не должна быть позже даты в поле "${params[1]}"`
        },
        latitude: () => 'Значение не является широтой',
        longitude: () => 'Значение не является долготой',
        requiredIf: (field, params) => {
          return `Поле обязательно при заполненном поле "${params[1]}"`
        },
        url: () => 'Поле имеет ошибочный формат URL',
        decimal: () => 'Значение поля должно быть числовым',
        numeric: () => 'Значение поля должно быть целым и положительным числом',
        integer: () => 'Значение поля должно быть целым числом',
        sameDay: (field, params) => `Дата должна быть в тот же день, что и "${params[1]}"`,
        notMore: (field, params) => `Значение должно быть не больше поля "${params[1]}"`,
        isYoutubeId: () => 'Значение не является валидным YouTube ID',
        sizeExist: () => 'Такой размер уже добавлен',
        samePasswords: () => 'Пароли не совпадают',
        maxFilesSize: (field, params) => `Общий размер файлов больше ${params[0]} МБ`,
        maxFileSize: (field, params) => `Размер файла больше ${params[0]} МБ`,
        maxUploadItems: (field, params) => `Кол-во элементов больше ${params[0]}`,
        extensions: (field, params) => `Неверный формат файла. Поддерживаемые форматы: ${params.join(', ')}`,
        color: () => 'Неверный формат цвета',
        uri: () => 'Неверный формат URI',
        uniqueIn: () => 'Значение поля должно быть уникальным',
        email: () => 'Введите верный e-mail',
        probabilitySum: () => 'Сумма вероятностей должна быть равна 100',
        min_value: (field, [min]) => `Значение поля должно быть равным ${min} или больше`,
        mnemocode: () => 'Значения поля должно содержать только латинские буквы и цифры',
        string: () => 'Значения поля содержит запрещенные символы',
      },
    },
  },
}
