var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-groups",class:{ loading: _vm.loading }},[_c('page-header',{attrs:{"title":"Группы пользователей","add-title":"Создать группу пользователей","show-add":_vm.$guard.hasPermission('GROUP', 'WRITER')},on:{"add":function($event){return _vm.$router.push({
      name: 'group',
      params: { id: 'create' },
      query: {
        from: _vm.$route.query,
      }
    })}}},[_c('template',{slot:"actions"},[_c('el-switch',{attrs:{"inactive-text":"Показывать удаленные"},on:{"input":_vm.changeIsExtended},model:{value:(_vm.params.isExtended),callback:function ($$v) {_vm.$set(_vm.params, "isExtended", $$v)},expression:"params.isExtended"}})],1)],2),(_vm.user.isAdmin)?_c('div',{staticClass:"filters"},[_c('el-select',{staticClass:"type",attrs:{"placeholder":"Тип"},on:{"change":_vm.changeFilters},model:{value:(_vm.params.isAdmin),callback:function ($$v) {_vm.$set(_vm.params, "isAdmin", $$v)},expression:"params.isAdmin"}},_vm._l((_vm.types),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"value":item.value,"disabled":item.disabled}})}),1)],1):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.table),expression:"loading.table"}],staticClass:"table-wrapper"},[(!_vm.loading.table)?_c('el-table',{ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"default-sort":{
        prop: _vm.$utils.upperToCamel(_vm.params.sort),
        order: _vm.getOrder(),
      },"row-key":"id"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Название","prop":"title"}}),_c('el-table-column',{attrs:{"align":"right","width":"300","class-name":"actions"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"el-icon-user","circle":"","title":"Пользователи группы"},on:{"click":function($event){return _vm.$router.push({
              name: 'group-users',
              params: { id: scope.row.id },
            })}}}),_c('el-button',{attrs:{"icon":"el-icon-edit","circle":"","title":"Редактировать"},on:{"click":function($event){return _vm.$router.push({
              name: 'group',
              params: { id: scope.row.id },
              query: {
                from: _vm.$route.query,
              }
            })}}}),(_vm.$guard.hasPermission('GROUP', 'OWNER'))?_c('btn-delete',{attrs:{"loading":_vm.loading.remove === scope.row.id,"is-deleted":scope.row.isDeleted,"confirm-text":"Вы действительно хотите группу пользователей?"},on:{"submit":function (isDeleted) { isDeleted ? _vm.restore(scope.row) : _vm.remove(scope.row) }}}):_vm._e()]}}],null,false,479262091)})],1):_vm._e()],1),_c('el-row',{staticStyle:{"padding":"16px 0"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.page,"page-size":_vm.params.limit,"hide-on-single-page":""},on:{"current-change":_vm.changePagination}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }