import MixinList from '@/mixins/list'

export default {
  name: 'page-questions',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'questions',
        messages: {
          save: 'Вопрос сохранен',
          remove: 'Вопрос удален',
          restore: 'Вопрос восстановлен',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
      },
    }
  },
}
