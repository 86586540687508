<template>
  <el-input
    :value="value"
    autocomplete="off"
    placeholder="Поиск"
    prefix-icon="el-icon-search"
    clearable
    @input="input"
  />
</template>

<script>
export default {
  name: 'input-search',
  props: [
    'value',
  ],
  data() {
    return {
      timeout: null,
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('search', value)
      }, 500)
    },
  },
}
</script>
