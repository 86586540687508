import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, { isAdmin, ...params }) {
      return Http.get('groups', {
        params,
        headers: !isAdmin ? utils.accessObjectsHeaders(rootState) : {},
      })
    },
    getSuggestions({ rootState }, params) {
      const requests = []
      if (rootState.accessObjects.id && rootState.accessObjects.type) {
        requests.push(Http.get('groups', {
          params,
          headers: utils.accessObjectsHeaders(rootState),
        }))
      }
      if (rootState.user.data?.isAdmin) {
        requests.push(Http.get('groups', {
          params,
        }))
      }
      if (requests.length) {
        return Promise.all(requests).then(data => {
          return {
            list: data.reduce((acc, item) => {
              return [
                ...acc,
                ...item.list,
              ]
            }, []),
          }
        })
      } else {
        return {
          list: [],
        }
      }
    },
    getById(ctx, id) {
      return Http.get(`groups/${id}`)
    },
    create({ rootState }, { data }) {
      const { isAdmin, ...form } = data
      return Http.post('groups', form, {
        headers: !isAdmin ? utils.accessObjectsHeaders(rootState) : {},
      })
    },
    edit({ rootState }, { data, id }) {
      const { isAdmin, ...form } = data
      return Http.put(`groups/${id}`, form, {
        headers: !isAdmin ? utils.accessObjectsHeaders(rootState) : {},
      })
    },
    delete(ctx, id) {
      return Http.delete(`groups/delete/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`groups/restore/${id}`)
    },
    addUsers(ctx, { id, data }) {
      return Http.post(`groups/${id}/users`, data)
    },
    removeUsers(ctx, { id, data }) {
      return Http.delete(`groups/${id}/users`, { data })
    },
  },
})
