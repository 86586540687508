import MixinList from '@/mixins/list'

export default {
  name: 'page-members',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'members',
      },
      params: {
        search: '',
        sort: '',
        direction: '',
      },
    }
  },
}
