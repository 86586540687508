import MixinList from '@/mixins/list'
import FormAddUsers from '@/components/forms/form-add-users/form-add-users.vue'

export default {
  name: 'page-group-users',
  mixins: [
    MixinList,
  ],
  components: {
    FormAddUsers,
  },
  data() {
    return {
      entity: {
        name: 'users',
      },
      params: {
        search: '',
        sort: '',
        direction: '',
        groupId: this.$route.params.id,
      },
      group: null,
      modals: {
        addUsers: false,
      },
    }
  },
  created() {
    this.$store.dispatch('groups/getById', this.$route.params.id).then(group => {
      this.group = group
    })
  },
  methods: {
    addUsers(data) {
      this.loading.modal = true
      this.$store.dispatch('groups/addUsers', {
        id: Number(this.$route.params.id),
        data,
      }).then(() => {
        this.modals.addUsers = false
        this.$message({
          message: 'Пользователи добавлены в группу',
          type: 'success',
        })
        this.getList()
      }).finally(() => {
        this.loading.modal = false
      })
    },
    remove(data) {
      this.loading.remove = data.id
      this.$store.dispatch('groups/removeUsers', {
        id: Number(this.$route.params.id),
        data: { userIds: [data.id] },
      }).then(() => {
        this.modals.addUsers = false
        this.$message({
          message: 'Пользователь удален из группы',
          type: 'success',
        })
        this.getList()
      }).finally(() => {
        this.loading.remove = false
      })
    },
  },
}
