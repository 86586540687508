import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    uploadImage({ rootState }, image) {
      const fd = new FormData()
      fd.append('image', image)
      return Http.post('images/upload', fd, {
        'Content-Type': 'multipart/form-data',
        headers: utils.accessObjectsHeaders(rootState),
      }).then(r => ({
        ...r,
        imageUri: utils.getCdnImageUri(r.imageUri, 400, 400),
      }))
    },
    getList({ rootState }, params) {
      return Http.get('images', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      }).then(data => ({
        ...data,
        list: data.list.map(i => ({
          ...i,
          imageUri: utils.getCdnImageUri(i.imageUri, 400, 400),
        })),
      }))
    },
    delete({ rootState }, id) {
      return Http.delete(`images/${id}`, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
  },
})
