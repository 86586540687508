export default {
  name: 'page-me',
  inject: ['$validator'],
  data() {
    return {
      loading: {
        save: false,
      },
      form: {
        login: null,
        password: null,
        passwordConfirm: null,
        givenName: null,
        surname: null,
        companyName: null,
        jobTitle: null,
        avatar: {
          gradient: null,
          imageUri: null,
        },
      },
    }
  },
  created() {
    this.data = this.convertUserData(this.$store.state.user.data)
    this.$utils.fillForm(this.data, this.form)
    if (this.form.avatar.imageUri) {
      this.form.avatar.imageUri.imageUri = this.$utils.getCdnImageUri(this.form.avatar.imageUri.imageUri, 400, 400)
    }
    if (!this.form.avatar.gradient) {
      this.form.avatar.gradient = {
        colorStart: '#252525',
        colorStop: '#AFAFAF',
      }
    }
  },
  methods: {
    convertUserData({ profile, ...data }) {
      return {
        ...data,
        ...profile,
      }
    },
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch('user/edit', this.$utils.prepareFormData(this.form)).then(() => {
            this.$message({
              message: 'Профиль сохранен',
              type: 'success',
            })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
  },
}
