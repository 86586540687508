import MixinList from '@/mixins/list'
import FormRewardInstance from '@/components/forms/form-reward-instance/form-reward-instance.vue'
import FormImportRewardInstances from '@/components/forms/form-import-reward-instances/form-import-reward-instances.vue'

export default {
  name: 'page-reward-instances',
  mixins: [
    MixinList,
  ],
  components: {
    FormRewardInstance,
    FormImportRewardInstances,
  },
  data() {
    return {
      entity: {
        name: 'rewardInstances',
        messages: {
          save: 'Экземпляр награды сохранен',
          remove: 'Экземпляр награды удален',
          restore: 'Экземпляр награды восстановлен',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
      },
      reward: null,
      upload: {
        show: false,
        loading: false,
      },
      issue: [
        { title: 'Выдан', value: true },
        { title: 'Не выдан', value: false },
      ],
    }
  },
  async created() {
    this.reward = await this.$store.dispatch('rewards/getById', this.$route.params.id)
  },
  methods: {
    getList() {
      this.loading.table = true
      this.$store.dispatch('rewardInstances/getList', {
        params: this.getQueryParams(),
        rewardId: this.$route.params.id,
      }).then(data => {
        this.total = data.total
        this.list = data.list
      }).finally(() => {
        this.loading.table = false
      })
    },
    create(data) {
      this.loading.modal = true
      this.$store.dispatch('rewardInstances/create', {
        data: this.$utils.prepareFormData(data),
        rewardId: this.$route.params.id,
      }).then(() => {
        this.$message({
          message: this.entity.messages.save,
          type: 'success',
        })
        this.item.show = false
        this.getList()
      }).catch(e => {
        this.$utils.addServerErrors(e, this.errors)
      }).finally(() => {
        this.loading.modal = false
      })
    },
    remove(data) {
      this.loading.remove = data.id
      this.$store.dispatch('rewardInstances/delete', data.id).then(() => {
        this.$message({
          message: this.entity.messages.remove,
          type: 'success',
        })
        this.setParamsAfterDelete()
        this.getList()
      }).finally(() => {
        this.loading.remove = false
      })
    },
    uploadInstances(data) {
      this.upload.loading = true
      this.$store.dispatch('rewardInstances/import', {
        file: data.file[0],
        rewardId: this.$route.params.id,
      }).then(() => {
        this.upload.show = false
        this.$message({
          message: 'Импорт успешно завершен',
          type: 'success',
        })
        this.getList()
      }).catch(e => {
        this.$utils.addServerErrors(e, this.errors)
      }).finally(() => {
        this.upload.loading = false
      })
    },
  },
}
