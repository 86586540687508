import Http from '@/services/http'
import utils from '@/common/utils'
import consts from '@/common/consts'

export default ({
  namespaced: true,
  state: {
    data: null,
    permissions: [],
  },
  mutations: {
    setUser(state, user) {
      state.data = user
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    mergePermissions(state, permissions) {
      state.permissions = state.permissions.map(up => {
        const permission = permissions.find(p => (up.entityType === p.entityType))
        const level = consts.permissionLevels[permission.permissionLevel]
        const uLevel = consts.permissionLevels[up.permissionLevel]
        return {
          ...up,
          permissionLevel: uLevel <= level ? permission.permissionLevel : up.permissionLevel,
        }
      })
    },
  },
  actions: {
    auth({ dispatch }, data) {
      return Http.post('auth/signIn', data).then(data => {
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('refreshToken', data.refreshToken)
        return dispatch('init')
      })
    },
    init({ dispatch }) {
      return dispatch('get').then(user => {
        return Promise.all([
          dispatch('getPermissions', false),
          dispatch('accessObjects/getTree', null, { root: true }),
          dispatch('common/getLocales', null, { root: true }),
          dispatch('common/getDateTimeOffsetSources', null, { root: true }),
          dispatch('targets/getNotificationTriggers', null, { root: true }),
        ]).then(([permissions]) => {
          return { user, permissions }
        })
      })
    },
    logout({ commit }) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('aoId')
      localStorage.removeItem('aoType')
      commit('setUser', null)
      commit('accessObjects/reset', null, { root: true })
      commit('setPermissions', [])
      return true
    },
    get({ state, commit }) {
      if (!state.data) {
        const token = localStorage.getItem('accessToken')
        if (token) {
          return Http.get('me').then(user => {
            user.profile.avatar.imageUri = utils.getCdnImage(user.profile.avatar.imageUri, 400, 400)
            commit('setUser', user)
            return user
          }).catch(e => {
            return Promise.reject(e)
          })
        }
        return Promise.reject(utils.serverError(401, {
          error: {
            code: 'UNAUTHORIZED_REQUEST',
            message: 'Токен доступа отсутствует',
          },
        }))
      }
      return state.data
    },
    getPermissions({ commit, rootState }, useAccessObject = true) {
      return Http.get('me/permission', {
        headers: useAccessObject ? utils.accessObjectsHeaders(rootState) : {},
      }).then(r => {
        commit(useAccessObject ? 'mergePermissions' : 'setPermissions', r.list)
        return r.list
      })
    },
    edit({ commit }, data) {
      return Http.post('me', data).then(user => {
        commit('setUser', user)
        return user
      })
    },
  },
  modules: {
  },
})
