import { mapState } from 'vuex'
import MixinSearching from '@/mixins/searching'
import TargetCriterions from '@/components/target-criterions/target-criterions.vue'
import ChainTargets from '@/components/chain-targets/chain-targets.vue'
import FormTargetActivation from '@/components/forms/form-target-activation/form-target-activation.vue'
import Notifications from '@/components/notifications/notifications.vue'

export default {
  name: 'page-target',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    TargetCriterions,
    ChainTargets,
    FormTargetActivation,
    Notifications,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        groupId: null,
        name: null,
        mnemocode: null,
        order: 0,
        recurrenceCapacity: null,
        deadline: null,
        deadlineOffsetSource: null,
        deadlineOffset: null,
        activity: {
          isActive: false,
          start: null,
          end: null,
        },
        sharedDeadline: {
          start: null,
          end: null,
        },
        title: [],
        description: [],
        criterions: [],
        attachments: [],
        rewardIds: [],
        midnightEnd: false,
        delay: null,
        chainTargetIds: [],
        notifications: [],
      },
      activeTab: 'common',
      deadline: {
        common: false,
        personal: false,
      },
      activity: false,
      modals: {
        activation: {
          show: false,
          loading: false,
        },
      },
    }
  },
  async created() {
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('targets/getById', this.id)
      this.data.criterions = this.data.criterions.map(c => ({
        ...c,
        rewardIds: c.rewards.map(r => r.id),
      }))
      this.$utils.fillForm(this.data, this.form)
      this.deadline.common = !!this.form.sharedDeadline.start || !!this.form.sharedDeadline.end
      this.deadline.personal = !!this.form.deadline
      this.activity = !!this.form.activity.start || !!this.form.activity.end
      this.form.rewardIds = this.data.rewards.map(r => r.id)
      this.form.chainTargetIds = this.data.chainTargets
      this.search.list = this.data.rewards
      this.setDeadlineUtc(this.form.deadlineOffset)
      this.loading.form = false
    }
  },
  computed: {
    ...mapState({
      offsetSources: state => state.common.dateTimeOffsetSources,
    }),
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`targets/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData(this.form),
          }).then(() => {
            this.$message({
              message: `Цель ${this.id ? 'отредактирована' : 'создана'}`,
              type: 'success',
            })
            this.$router.push({ name: 'targets' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('targets/delete', this.data.id).then(() => {
        this.$router.push({ name: 'targets' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('targets/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'targets', query: this.$route.query.from })
    },
    changeDeadline(type, active) {
      if (type === 'common') {
        this.form.sharedDeadline.start = null
        this.form.sharedDeadline.end = null
      } else if (type === 'personal') {
        this.form.deadline = null

        if (!active) {
          this.form.midnightEnd = false
        }
      }
    },
    changeActivity() {
      this.form.activity.start = null
      this.form.activity.end = null
      this.$validator.reset('activity.start')
      this.$validator.reset('activity.end')
    },
    activate(data) {
      this.modals.activation.loading = true
      this.$store.dispatch('targets/activate', {
        id: this.id,
        data: this.$utils.prepareFormData(data),
      }).then(() => {
        this.$message({
          message: 'Цель активирована',
          type: 'success',
        })
        this.$router.push({ name: 'targets' })
      }).finally(() => {
        this.modals.activation.loading = false
      })
    },
    changeDeadlineOffsetSource(value) {
      if (value !== 'CURRENT_ENTITY') {
        this.form.deadlineOffset = null
        this.setDeadlineUtc('+00:00')
      } else {
        this.setDeadlineUtc(this.form.deadlineOffset)
        this.$validator.reset('deadlineOffset')
      }
    },
    setDeadlineUtc(utc) {
      ['start', 'end'].forEach(i => {
        if (utc && this.form.sharedDeadline[i]) {
          this.form.sharedDeadline[i] = this.$moment.parseZone(this.form.sharedDeadline[i])
            .utcOffset(utc, true)
            .format()
            .replace('Z', '+00:00')
        }
      })
    },
  },
}
