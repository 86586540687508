import moment from 'moment'
import plural from 'plural-ru'
import consts from '@/common/consts'
import store from '@/store'

const asObject = prop => {
  return consts[prop].reduce((acc, item) => {
    acc[item.value] = item.title
    return acc
  }, {})
}

export default {
  filters: {
    targetActivityInterval: ({ from, to }) => {
      const result = []
      if (from) {
        result.push(`c ${moment(from).format('DD.MM.YYYY')}`)
      }
      if (to) {
        result.push(`до ${moment(to).format('DD.MM.YYYY')}`)
      }
      return result.length ? result.join(' ') : 'Не ограничено'
    },
    duration: ({ from, to, format = 'days', hasPostfix = true }) => {
      if (from) {
        to = to || moment()

        const postfix = {
          days: ['день', 'дня', 'дней'],
          years: ['год', 'года', 'лет'],
          months: ['месяц', 'месяца', 'месяцев'],
        }

        const duration = moment(from).diff(to, format)
        return `${duration} ${hasPostfix ? plural(duration, ...postfix[format]) : ''}`
      }
    },
    accessObjectType: type => {
      return asObject('accessObjectTypes')[type] || null
    },
    blacklistReason: reason => {
      return asObject('blacklistReasons')[reason] || null
    },
    notificationTemplateEntity: entity => {
      return asObject('notificationTemplateEntities')[entity] || null
    },
    rewardType: type => {
      return store.state.rewards.types.find(t => t.value === type)?.title
    },
    phone: str => {
      const cleaned = (`${str}`).replace(/\D/g, '')
      const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{4})$/)

      if (match) {
        const intlCode = (match[1] ? '+7 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }

      return str
    },
    userInfo: item => {
      const arr = [item.profile.companyName, item.profile.jobTitle].filter(i => !!i)
      const sub = arr.length ? `(${arr.join(', ')})` : ''
      return `${item.login} ${sub}`
    },
    userName: item => {
      return `${item.profile.surname} ${item.profile.givenName}`
    },
  },
  install(Vue) {
    const filters = Object.keys(this.filters)
    filters.forEach(k => Vue.filter(k, this.filters[k]))
  },
}
