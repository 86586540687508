<template>
  <el-select
    :value="value"
    :name="name"
    :placeholder="placeholder"
    :clearable="clearable"
    class="select-timezones"
    @input="value => $emit('input', value)"
  >
    <el-option
      v-for="item in timezones"
      :key="item.name"
      :label="item.label"
      :value="item.utc"
    />
  </el-select>
</template>

<script>
import timezones from '@/common/timezones'

export default {
  name: 'select-timezones',
  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timezones,
    }
  },
}
</script>
