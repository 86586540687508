import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('quizes', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`quizes/${id}`).then(q => ({
        ...q,
        image: utils.getCdnImage(q.image, 400, 400),
        attachments: utils.getAttachmentsWithCdnImages(q.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('quizes', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`quizes/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`quizes/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`quizes/restore/${id}`)
    },
    addQuestions(ctx, { id, data }) {
      return Http.post(`quizes/${id}/questions`, data)
    },
    removeQuestions(ctx, { id, data }) {
      return Http.delete(`quizes/${id}/questions`, { data })
    },
  },
})
