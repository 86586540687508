import consts from '@/common/consts'
import LocalizedStringSet from '@/components/ui/localized-string-set/localized-string-set.vue'

export default {
  name: 'page-notification-template',
  inject: ['$validator'],
  components: {
    LocalizedStringSet,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        name: null,
        mnemocode: null,
        defaultPriority: 0,
        entity: 'TARGET',
        title: [],
        message: [],
        extendedMessage: [],
        deeplink: null,
        image: null,
      },
      priorities: [
        { title: 'Низкая', value: -2 },
        { title: 'Пониженная', value: -1 },
        { title: 'Стандартная', value: 0 },
        { title: 'Повышенная', value: 1 },
        { title: 'Высокая', value: 2 },
      ],
      variables: [],
      entities: consts.notificationTemplateEntities,
    }
  },
  async created() {
    this.loading.form = true
    if (this.id) {
      this.data = await this.$store.dispatch('notificationTemplates/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      this.form.message = this.data.message || []
      this.form.extendedMessage = this.data.extendedMessage || []
    }
    await this.getVariables(this.form.entity)
    this.loading.form = false
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`notificationTemplates/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData({
              ...this.form,
              message: this.form.message.length ? this.form.message : null,
              extendedMessage: this.form.extendedMessage.length ? this.form.extendedMessage : null,
            }),
          }).then(() => {
            this.$message({
              message: `Шаблон ${this.id ? 'отредактирован' : 'создан'}`,
              type: 'success',
            })
            this.$router.push({ name: 'notification-templates' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('notificationTemplates/delete', this.data.id).then(() => {
        this.$router.push({ name: 'notificationTemplates' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('notificationTemplates/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'notification-templates', query: this.$route.query.from })
    },
    async getVariables(entity) {
      this.variables = await this.$store.dispatch('notificationTemplates/getVariables', entity)
    },
  },
}
