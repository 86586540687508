import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('lotteries', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`lotteries/${id}`).then(l => ({
        ...l,
        attachments: utils.getAttachmentsWithCdnImages(l.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('lotteries', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.post(`lotteries/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`lotteries/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`lotteries/restore/${id}`)
    },
    changeStatus(ctx, { id, status }) {
      return Http.post(`lotteries/holding/${id}/status`, { status })
    },
  },
})
