import MixinForm from '@/mixins/form'
import { mapState } from 'vuex'

export default {
  name: 'form-frontoffice',
  mixins: [
    MixinForm,
  ],
  data() {
    return {
      form: {
        brandId: null,
        isDeleted: false,
        title: null,
        mnemocode: null,
        dateTimeOffset: null,
        delayDeactivatingIsAllow: false,
      },
    }
  },
  computed: {
    ...mapState({
      tree: state => state.accessObjects.tree,
    }),
    brand() {
      const ao = this.$utils.findAccessObject(this.data.brandId, 'BRAND', this.tree)
      return ao ? { title: ao.title, id: ao.id } : {}
    },
  },
}
