import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  state: {
    notificationTriggers: [],
  },
  mutations: {
    setNotificationTriggers(state, data) {
      state.notificationTriggers = data
    },
  },
  actions: {
    getList({ rootState }, params) {
      return Http.get('targets', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`targets/${id}`).then(t => ({
        ...t,
        attachments: utils.getAttachmentsWithCdnImages(t.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('targets', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`targets/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`targets/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`targets/restore/${id}`)
    },
    activate({ rootState }, { id, data }) {
      return Http.post(`targets/${id}/activate`, data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getNotificationTriggers({ commit, state }) {
      if (!state.notificationTriggers.length) {
        return Http.get('userTargetStatuses').then(r => {
          const list = r.list.map(s => ({
            title: s.title,
            value: s.status,
          }))
          commit('setNotificationTriggers', list)
          return list
        })
      }
      return state.notificationTriggers
    },
  },
})
