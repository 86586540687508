<template>
  <div class="priority">
    <div
      v-for="index in 5"
      :key="index"
      :class="{ fill: (index - 4) < value }"
      class="item"
    />
  </div>
</template>

<script>
export default {
  name: 'priority',
  props: {
    value: {
      required: true,
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
.priority {
  display: flex;
  .item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid $dark;
    margin-left: 4px;

    &.fill {
      background: $dark;
    }
  }
}
</style>
