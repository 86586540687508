import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const masks = {
  probability: createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: false,
    decimalLimit: 4,
    integerLimit: 3,
  }),
  positiveNumber: createNumberMask({
    prefix: '',
    allowDecimal: false,
    allowNegative: false,
    integerLimit: null,
    includeThousandsSeparator: false,
  }),
}

export default {
  install(Vue) {
    Vue.prototype.$masks = masks
  },
}
