import MixinList from '@/mixins/list'
import MixinSearching from '@/mixins/searching'
import consts from '@/common/consts'

export default {
  name: 'page-targets',
  mixins: [
    MixinList,
    MixinSearching,
  ],
  data() {
    return {
      entity: {
        name: 'targets',
        messages: {
          save: 'Цель сохранена',
          remove: 'Цель удалена',
          restore: 'Цель восстановлена',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
        isActive: null,
        activityStart: null,
        activityEnd: null,
        rewardIds: null,
        criterionTypes: null,
      },
      activities: [
        { title: 'Активна', value: true },
        { title: 'Не активна', value: false },
      ],
      criterionCategories: consts.targetCriterionCategories,
    }
  },
}
