import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList(ctx, params) {
      return Http.get('users', {
        params,
      }).then(data => ({
        ...data,
        list: data.list.map(u => {
          u.profile.avatar.imageUri = utils.getCdnImage(u.profile.avatar.imageUri, 160, 160)
          return u
        }),
      }))
    },
    getById(ctx, id) {
      return Http.get(`users/${id}`).then(u => {
        u.profile.avatar.imageUri = utils.getCdnImage(u.profile.avatar.imageUri, 160, 160)
        return u
      })
    },
    create(ctx, { data }) {
      return Http.post('users', data)
    },
    edit(ctx, { data, id }) {
      return Http.post(`users/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`users/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`users/restore/${id}`)
    },
  },
})
