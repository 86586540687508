import MixinForm from '@/mixins/form'

export default {
  name: 'form-brand',
  mixins: [
    MixinForm,
  ],
  data() {
    return {
      form: {
        isDeleted: false,
        title: null,
        country: null,
        mnemocode: null,
        delayDeactivatingIsAllow: false,
      },
    }
  },
  created() {
    this.$store.dispatch('common/getCountries')
  },
}
