import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('targets/typicalActions', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`targets/typicalActions/${id}`)
    },
    create({ rootState }, { data }) {
      return Http.post('targets/typicalActions', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`targets/typicalActions/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`targets/typicalActions/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`targets/typicalActions/restore/${id}`)
    },
  },
})
