<template>
  <div class="localized-string-set">
    <div class="list">
      <div v-for="(item, index) in value" :key="index" class="item">
        <el-form-item
          :error="errors.first(`${name}[${index}].value`)"
          :show-message="false"
          class="title"
        >
          <quill
            v-model="item.value"
            v-validate="'required'"
            :name="`${name}[${index}].value`"
            :variables="variables"
            :placeholder="placeholder"
            :class="{ 'has-error': errors.has(`${name}[${index}].value`) }"
          />
          <popup-error :error="errors.first(`${name}[${index}].value`)" />
        </el-form-item>

        <el-form-item
          :error="errors.first(`${name}[${index}].locale`)"
          :show-message="false"
          class="locale"
        >
          <el-select
            v-model="item.locale"
            v-validate="{
              required: true,
            }"
            :name="`${name}[${index}].locale`"
            placeholder="Язык"
          >
            <el-option
              v-for="l in locales"
              :key="l.value"
              :label="l.title"
              :value="l.value"
              :disabled="isUnique && value.filter(i => i.locale === l.value).length > 0"
            />
          </el-select>

          <popup-error :error="errors.first(`${name}[${index}].locale`)" />
        </el-form-item>

        <el-button
          :disabled="required && value.length < 2"
          title="Удалить"
          icon="el-icon-delete"
          type="danger"
          circle
          class="remove"
          @click="remove(index)"
        />
      </div>
    </div>

    <div class="bottom">
      <el-button
        v-if="value.length < locales.length || !isUnique"
        icon="el-icon-plus"
        type="primary"
        round
        class="add"
        @click="add"
      >Добавить локализацию</el-button>
    </div>
  </div>
</template>

<script>
import Quill from './quill-textarea/quill-textarea.vue'

export default {
  name: 'localized-string-set',
  inject: ['$validator'],
  components: {
    Quill,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    locales: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    isUnique: {
      type: Boolean,
      default: true,
    },
    variables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
    }
  },
  created() {
    if (!this.value.length && this.required) {
      this.add()
    }
  },
  methods: {
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
    add() {
      this.$emit('input', [
        ...this.value,
        { locale: null, value: null },
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
.localized-string-set {
  .list {
    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 10px;

      .title {
        flex-grow: 1;
      }

      .remove, .locale {
        margin-left: 25px;
      }

      .locale {
        width: 250px;
        margin-bottom: 0;
        flex-shrink: 0;
      }
    }
  }

  .bottom {
    display: flex;
    padding-top: 10px;
  }
}
</style>
