import MixinList from '@/mixins/list'
import FormAddQuestion from '@/components/forms/form-add-question/form-add-question.vue'

export default {
  name: 'page-quiz-questions',
  mixins: [
    MixinList,
  ],
  components: {
    FormAddQuestion,
  },
  data() {
    return {
      entity: {
        name: 'questions',
      },
      params: {
        search: '',
        sort: '',
        direction: '',
        quizId: this.$route.params.id,
      },
      quiz: null,
      modals: {
        addQuestions: false,
      },
    }
  },
  async created() {
    this.quiz = await this.$store.dispatch('quizes/getById', this.$route.params.id)
  },
  methods: {
    addQuestions(data) {
      this.loading.modal = true
      this.$store.dispatch('quizes/addQuestions', {
        id: Number(this.$route.params.id),
        data,
      }).then(() => {
        this.modals.addQuestions = false
        this.$message({
          message: 'Вопросы добавлены в тест',
          type: 'success',
        })
        this.getList()
      }).finally(() => {
        this.loading.modal = false
      })
    },
    remove(data) {
      this.loading.remove = data.id
      this.$store.dispatch('quizes/removeQuestions', {
        id: Number(this.$route.params.id),
        data: { questionsIds: [data.id] },
      }).then(() => {
        this.$message({
          message: 'Вопрос удален из теста',
          type: 'success',
        })
        this.getList()
      }).finally(() => {
        this.loading.remove = false
      })
    },
  },
}
