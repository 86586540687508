<template>
  <div class="popup-error">
    <i v-if="!!error" class="el-icon-warning" />

    <div v-if="!!error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'popup-error',
  props: [
    'error',
  ],
}
</script>

<style lang="scss" scoped>
.popup-error {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .el-icon-warning {
    position: absolute;
    left: -9px;
    top: -9px;
    font-size: 16px;
    color: #f56c6c;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #fff;
    width: 19px;
    height: 19px;

    &:hover {
      color: #f78989;

      & + .error {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .error {
    position: absolute;
    top: calc(100% + 10px);
    left: 10px;
    z-index: 100;
    border: 1px solid #f56c6c;
    color: #f56c6c;
    padding: 10px;
    border-radius: 4px;
    font-size: 12px;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all .2s linear;
    width: 250px;
    line-height: normal;
  }
}
</style>
