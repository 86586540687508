<template>
  <div class="date-picker">
    <el-date-picker
      :value="datetime"
      :picker-options="{ firstDayOfWeek: 1 }"
      :placeholder="placeholder"
      :disabled="disabled"
      value-format="yyyy-MM-ddTHH:mm:ss"
      format="dd.MM.yy HH:mm"
      type="datetime"
      @change="submitValue"
      @input="changeValue"
    />

    <select-timezones
      v-if="editUtc"
      v-model="utc"
      @input="changeUtc"
    />
  </div>
</template>

<script>
export default {
  name: 'date-picker',
  props: {
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editUtc: {
      type: Boolean,
      default: false,
    },
    defaultUtc: {
      default: '+00:00',
    },
  },
  data() {
    return {
      utc: null,
      datetime: null,
    }
  },
  methods: {
    changeValue(datetime) {
      if (datetime) {
        const value = this.$moment.parseZone(datetime)
          .utcOffset(this.utc, true)
          .format()
          .replace('Z', '+00:00')

        this.datetime = value
      } else {
        this.datetime = datetime
      }
    },
    changeUtc(utc) {
      if (this.value && utc) {
        const value = this.$moment.parseZone(this.value)
          .utcOffset(utc, true)
          .format()
          .replace('Z', '+00:00')

        this.$emit('input', value)
      }
    },
    submitValue() {
      this.$emit('input', this.datetime)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.datetime = value
        this.utc = value ? this.$moment.parseZone(value).format('Z') : this.defaultUtc
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.date-picker {
  display: flex;
  width: 100% !important;
  height: auto !important;

  .select-timezones {
    margin-left: 10px;
    flex-shrink: 0;
    width: 150px;
  }
}
</style>
