import MixinList from '@/mixins/list'

export default {
  name: 'page-lotteries',
  mixins: [
    MixinList,
  ],
  data() {
    return {
      entity: {
        name: 'lotteries',
        messages: {
          save: 'Розыгрыш сохранен',
          remove: 'Розыгрыш удален',
          restore: 'Розыгрыш восстановлен',
        },
      },
      params: {
        search: null,
        sort: null,
        direction: null,
      },
    }
  },
}
