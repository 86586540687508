import MixinForm from '@/mixins/form'
import MixinSearching from '@/mixins/searching'

export default {
  name: 'form-add-question',
  mixins: [
    MixinForm,
    MixinSearching,
  ],
  data() {
    return {
      form: {
        questionsIds: [],
      },
      value: [],
      timeout: false,
    }
  },
}
