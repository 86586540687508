<template>
  <div class="btn-delete">
    <el-popconfirm
      v-show="!isDeleted"
      :title="confirmText"
      confirm-button-text="Да"
      cancel-button-text="Нет"
      icon="el-icon-info"
      icon-color="red"
      @confirm="$emit('submit', false)"
    >
      <el-button
        :loading="loading"
        :title="titleDelete || 'Удалить'"
        slot="reference"
        type="danger"
        icon="el-icon-delete"
        :circle="!round"
        :round="round"
      >
        <template v-if="round">
          {{ titleDelete || 'Удалить' }}
        </template>
      </el-button>
    </el-popconfirm>

    <el-button
      v-show="isDeleted"
      title="Восстановить"
      :loading="loading"
      type="warning"
      icon="el-icon-refresh"
      :circle="!round"
      :round="round"
      @click="$emit('submit', true)"
    >
      <template v-if="round">
        Восстановить
      </template>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'btn-delete',
  props: {
    isDeleted: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    titleDelete: {
      type: String,
    },
    round: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.btn-delete {
  display: inline-flex;
}
</style>
