import { render, staticRenderFns } from "./form-user.vue?vue&type=template&id=5fb38402&scoped=true&"
import script from "./form-user.js?vue&type=script&lang=js&"
export * from "./form-user.js?vue&type=script&lang=js&"
import style0 from "./form-user.scss?vue&type=style&index=0&id=5fb38402&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb38402",
  null
  
)

export default component.exports