<template>
  <div class="page-header" :class="{ 'has-back': hasBackEmit }">
    <el-page-header
      title=""
      @back="$emit('back')"
    >
      <div class="content" slot="content">
        <div class="col">
          <div class="title">{{ title }}</div>
          <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
        </div>

        <el-button
          v-if="showAdd"
          type="primary"
          icon="el-icon-plus"
          :title="addTitle"
          circle
          @click="$emit('add')"
        ></el-button>
      </div>
    </el-page-header>

    <div class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  props: [
    'title',
    'actions',
    'confirmDeleteText',
    'subtitle',
    'showAdd',
    'addTitle',
  ],
  computed: {
    hasBackEmit() {
      return !!this.$listeners && !!this.$listeners.back
    },
  },
}
</script>

<style lang="scss">
.page-header {
  padding: 0 50px;
  border-bottom: 1px solid #dcdfe6;
  background: #fff;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 300px;
  width: calc(100% - 300px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  max-width: 1520px;

  .content {
    display: flex;
    align-items: center;

    .col {
      margin-right: 15px;
    }

    .subtitle {
      font-size: 12px;
      color: grey;
      line-height: 16px;
    }
  }

  &:not(.has-back) {
    .el-page-header__left {
      display: none;
    }
  }

  .actions {
    display: flex;

    & > * {
      margin-left: 10px;
    }
  }
}
</style>
