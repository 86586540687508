import Http from '@/services/http'
import utils from '@/common/utils'

export default ({
  namespaced: true,
  actions: {
    getList({ rootState }, params) {
      return Http.get('lootboxes', {
        params,
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    getById(ctx, id) {
      return Http.get(`lootboxes/${id}`).then(l => ({
        ...l,
        attachments: utils.getAttachmentsWithCdnImages(l.attachments),
      }))
    },
    create({ rootState }, { data }) {
      return Http.post('lootboxes', data, {
        headers: utils.accessObjectsHeaders(rootState),
      })
    },
    edit(ctx, { data, id }) {
      return Http.put(`lootboxes/${id}`, data)
    },
    delete(ctx, id) {
      return Http.delete(`lootboxes/${id}`)
    },
    restore(ctx, id) {
      return Http.post(`lootboxes/restore/${id}`)
    },
  },
})
