import draggable from 'vuedraggable'

const answer = {
  image: null,
  name: null,
  isCorrectAnswer: false,
  title: [],
  description: [],
  attachments: [],
}

export default {
  name: 'answers',
  inject: ['$validator'],
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: 'answers',
    },
  },
  data() {
    return {
      modal: {
        show: false,
        index: null,
      },
      form: {
        ...answer,
      },
      activeTab: 'common',
    }
  },
  methods: {
    submit() {
      this.$validator.validateAll('answer').then(valid => {
        if (valid) {
          if (this.modal.index === null) {
            this.$emit('input', [
              ...this.value,
              { ...this.form },
            ])
          } else {
            this.value.splice(this.modal.index, 1, { ...this.form })
          }
          this.modal.show = false
        } else {
          this.$utils.scrollToError(this.$refs.answerTabs.$el)
        }
      })
    },
    remove(index) {
      this.$emit('input', this.value.filter((item, i) => i !== index))
    },
    openForm(data = null, index = null) {
      this.form = { ...answer }
      this.modal.index = index !== null ? index : this.value.length
      if (data) {
        this.$utils.fillForm(data, this.form)
      } else {
        this.$validator.reset('answer')
      }
      this.activeTab = 'common'
      this.modal.show = true
    },
    drag(value) {
      this.$emit('input', value)
    },
  },
}
