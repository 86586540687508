import UploadGallery from '@/components/upload-gallery/upload-gallery.vue'

export default {
  name: 'upload-image',
  inject: ['$validator'],
  components: {
    UploadGallery,
  },
  data() {
    return {
      loading: false,
      modal: {
        show: false,
      },
      current: null,
      src: null,
    }
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    modelFormat: {
      type: String,
      default: 'string',
    },
  },
  created() {
    if (this.value && this.value.fileId) {
      this.current = this.value
      this.src = this.value.imageUri
      this.$emit('input', this.getModel(this.value))
    }
  },
  methods: {
    upload({ target }) {
      this.$validator.validate(this.name).then(valid => {
        if (valid) {
          const [file] = target.files

          if (file) {
            this.loading = true
            this.$store.dispatch('files/uploadImage', file).then(r => {
              this.src = r.imageUri
              this.close()
              this.current = r
              this.$emit('input', this.getModel(r))
            }).finally(() => {
              this.loading = false
              this.$refs.form.reset()
            })
          }
        }
      })
    },
    remove() {
      this.current = null
      this.$validator.reset(this.name)
      this.src = null
      this.$emit('input', null)
    },
    submit() {
      this.src = this.current.imageUri
      this.$emit('input', this.getModel(this.current))
      this.close()
    },
    close() {
      this.$refs.form.reset()
      this.$validator.reset(this.name)
      this.modal.show = false
    },
    getModel(value) {
      return this.modelFormat === 'object' ? {
        fileId: value.fileId,
        imageUri: value.imageUri,
      } : value.fileId
    },
  },
}
