export default {
  data() {
    return {
      search: {
        list: null,
        loading: false,
        timeout: null,
      },
    }
  },
  methods: {
    searching(search = '', action, index = null, params = {}) {
      this.search.loading = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch(action, {
          search,
          isExtended: false,
          limit: 100,
          ...params,
        }).then(r => {
          if (index !== null) {
            this.search.list[index] = r.list
          } else {
            this.search.list = r.list
          }
        }).finally(() => {
          this.search.loading = false
        })
      }, 500)
    },
  },
}
