import MixinSearching from '@/mixins/searching'
import LocalizedStringSet from '@/components/ui/localized-string-set/localized-string-set.vue'
import Answers from '@/components/answers/answers.vue'

export default {
  name: 'page-question',
  inject: ['$validator'],
  mixins: [
    MixinSearching,
  ],
  components: {
    LocalizedStringSet,
    Answers,
  },
  data() {
    return {
      data: null,
      loading: {
        form: false,
        remove: false,
        save: false,
      },
      form: {
        name: null,
        image: null,
        isActive: false,
        correctAnswer: {
          value: null,
        },
        answers: [],
        title: [],
        description: [],
        attachments: [],
        quisIds: [],
      },
      type: 'ANSWER_OPTIONS',
      types: [
        { title: 'С вариантами ответов', value: 'ANSWER_OPTIONS', formValue: null },
        { title: 'С текстовым ответом', value: 'TEXT_ANSWER', formValue: [] },
      ],
      activeTab: 'common',
    }
  },
  async created() {
    if (this.id) {
      this.loading.form = true
      this.data = await this.$store.dispatch('questions/getById', this.id)
      this.$utils.fillForm(this.data, this.form)
      delete this.form.correctAnswer.answerIds
      this.loading.form = false
      if (this.form.correctAnswer.value && this.form.correctAnswer.value.length) {
        this.type = 'TEXT_ANSWER'
      }
      this.search.list = this.data.quizes
      this.form.quizIds = this.data.quizes.map(q => q.id)
    }
  },
  computed: {
    id() {
      const id = this.$route.params.id
      return id === 'create' ? null : Number.parseInt(id, 10)
    },
  },
  methods: {
    save() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.loading.save = true
          this.$store.dispatch(`questions/${this.id ? 'edit' : 'create'}`, {
            id: this.id,
            data: this.$utils.prepareFormData({
              ...this.form,
              answers: this.form.answers.map((i, index) => ({
                ...i,
                order: index + 1,
                image: i.image ? i.image.fileId : null,
                attachments: i.attachments.map(a => ({
                  ...a,
                  value: a.type === 'IMAGE' ? a.value.fileId : a.value,
                })),
              })),
            }),
          }).then(() => {
            this.$message({
              message: `Вопрос ${this.id ? 'отредактирован' : 'создан'}`,
              type: 'success',
            })
            this.$router.push({ name: 'questions' })
          }).catch(e => {
            this.$utils.addServerErrors(e, this.errors)
          }).finally(() => {
            this.loading.save = false
          })
        } else {
          this.$utils.scrollToError()
        }
      })
    },
    remove() {
      this.loading.remove = true
      this.$store.dispatch('questions/delete', this.data.id).then(() => {
        this.$router.push({ name: 'questions' })
      })
    },
    restore() {
      this.loading.remove = true
      this.$store.dispatch('questions/restore', this.data.id).then(() => {
        this.data.isDeleted = false
      }).finally(() => {
        this.loading.remove = false
      })
    },
    back() {
      this.$router.push({ name: 'questions', query: this.$route.query.from })
    },
    selectType(value) {
      const type = this.types.find(t => t.value === value)
      this.form.correctAnswer.value = type.formValue
      this.form.answers = []
    },
  },
}
