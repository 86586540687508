<template>
  <div class="localizations">
    <el-form-item
      label="Заголовок*"
      class="x1"
    >
      <localized-string-set
        v-model="value.title"
        :locales="$store.state.common.locales"
        :required="true"
        :name="`${name}title`"
        placeholder="Заголовок"
      />
    </el-form-item>

    <el-form-item
      label="Описание"
      class="x1"
    >
      <localized-string-set
        v-model="value.description"
        :locales="$store.state.common.locales"
        :name="`${name}description`"
        placeholder="Описание"
      />
    </el-form-item>
  </div>
</template>

<script>
import LocalizedStringSet from '@/components/ui/localized-string-set/localized-string-set.vue'

export default {
  name: 'localizations',
  components: {
    LocalizedStringSet,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.localizations {
  .el-form-item {
    border-bottom: 1px solid $form-border;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 10px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
</style>
