<template>
  <div :class="{ error }" class="upload-file">
    <el-button class="btn">
      <span class="text">{{ value ? value[0].name : text }}</span>
      <label v-if="!disabled" @click="$refs.file.click()" />
    </el-button>

    <form ref="form">
      <input
        :accept="accept"
        type="file"
        class="file"
        ref="file"
        @change="e => $emit('input', e.target.files)"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: 'upload-file',
  props: [
    'text',
    'value',
    'error',
    'disabled',
    'accept',
  ],
}
</script>

<style lang="scss" scoped>
.upload-file {
  &.error {
    button {
      border-color: #f56c6c;
    }
  }

  .btn {
    position: relative;
    width: 100%;

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
    }

    label {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .file, form {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
</style>
